/* Matter Appointments */
.lp-calendar-date {
  &.appointments {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
    left: 0;
    padding: 0.8rem;
    position: absolute;
    top: 0;
    z-index: 2;

    > span {
      color: var(--lp-secondary);
    }

    .react-datepicker-wrapper {
      background-color: var(--lp-white);
      border: 0.1rem solid var(--lp-secondary-400);
      border-radius: 0.4rem;
      color: var(--lp-text);
      display: block;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.5;
      padding: 0.8rem 0;
      width: auto;
    }

    .react-datepicker__tab-loop {
      position: absolute;
      top: 0;
    }
  }
}
