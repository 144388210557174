/* Loader */
.lp-loader {
  .big {
    &:before {
      background: rgba(var(--lp-white-rgb), 0.9);
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 1800;
    }

    .inner {
      height: 6.4rem;
      left: 50%;
      position: fixed;
      top: 50%;
      transform: translate(-50%,-50%);
      width: 6.4rem;
      z-index: 1900;

      div {
        animation: lp-loader-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        transform-origin: 3.2rem 3.2rem;
  
        &:after {
          background: var(--lp-primary);
          border-radius: 50%;
          content: " ";
          display: block;
          height: 0.6rem;
          margin: -0.3rem 0 0 -0.3rem;
          position: absolute;
          width: 0.6rem;
        }
  
        &:nth-child(1) {
          animation-delay: -0.036s;
  
          &:after {
            left: 5rem;
            top: 5rem;
          }
        }
  
        &:nth-child(2) {
          animation-delay: -0.072s;
  
          &:after {
            left: 4.5rem;
            top: 5.4rem;
          }
        }
  
        &:nth-child(3) {
          animation-delay: -0.108s;
  
          &:after {
            left: 3.9rem;
            top: 5.7rem;
          }
        }
  
        &:nth-child(4) {
          animation-delay: -0.144s;
  
          &:after {
            left: 3.2rem;
            top: 5.8rem;
          }
        }
  
        &:nth-child(5) {
          animation-delay: -0.18s;
  
          &:after {
            left: 2.5rem;
            top: 5.7rem;
          }
        }
  
        &:nth-child(6) {
          animation-delay: -0.216s;
  
          &:after {
            left: 1.9rem;
            top: 5.4rem;
          }
        }
  
        &:nth-child(7) {
          animation-delay: -0.252s;
  
          &:after {
            left: 1.4rem;
            top: 5rem;
          }
        }
  
        &:nth-child(8) {
          animation-delay: -0.288s;
  
          &:after {
            left: 1rem;
            top: 4.5rem;
          }
        }
      }
    }

    .lp-loader-text {
      left: 50%;
      position: fixed;
      top: calc(50% + 5rem);
      transform: translate(-50%,-50%);
      z-index: 1900;
    }
  }

  .inline {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    min-height: 1rem;
    min-width: 5rem;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9;

    &:before {
      background: rgba(var(--lp-white-rgb), 0.75);
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 20;
    }

    .inner {
      height: 1rem;
      position: relative;
      width: 6rem;

      div {
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
        background: var(--lp-primary);
        border-radius: 50%;
        height: 1rem;
        left: 50%;
        position: absolute;
        transform: translate(-50%, 0);
        width: 1rem;
        z-index: 25;
  
        &:nth-child(1) {
          animation: lp-loader-ellipsis1 0.6s infinite;
          left: 0.6rem;
        }
  
        &:nth-child(2) {
          animation: lp-loader-ellipsis2 0.6s infinite;
          left: 0.6rem;
        }
  
        &:nth-child(3) {
          animation: lp-loader-ellipsis2 0.6s infinite;
          left: 2.6rem;
        }
  
        &:nth-child(4) {
          animation: lp-loader-ellipsis3 0.6s infinite;
          left: 4.5rem;
        }
      }
    }
  }
}

@keyframes lp-loader-roller {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
}

@keyframes lp-loader-ellipsis1 {
  0% {
      transform: scale(0);
  }

  100% {
      transform: scale(1);
  }
}

@keyframes lp-loader-ellipsis2 {
  0% {
      transform: translate(0, 0);
  }

  100% {
      transform: translate(1.9rem, 0);
  }
}

@keyframes lp-loader-ellipsis3 {
  0% {
      transform: scale(1);
  }

  100% {
      transform: scale(0);
  }
}
