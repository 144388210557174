/* React Select */
.lp-select {
  .lp-select-color-option {
    align-items: center;
    display: flex;
    flex-direction: row;
    pointer-events: none;

    > span {
      border-radius: 2rem;
      display: block;
      height: 1.6rem;
      margin-right: 0.8rem;
      width: 1.6rem;

      &.unknown {
        background: var(--lp-secondary);
      }
    
      &.free {
        background: var(--lp-success);
      }
    
      &.tentative {
        background: var(--lp-info);
      }
    
      &.busy {
        background: var(--lp-primary);
      }
    
      &.out-of-office {
        background: var(--lp-danger);
      }
    
      &.working-elsewhere {
        background: var(--lp-primary-alt);
      }

      &.fail {
        background: var(--lp-danger);
      }

      &.pass {
        background: var(--lp-success);
      }

      &.pending {
        background: var(--lp-warning);
      }
    }
  }

  .lp-select__control {
    border-color: var(--lp-secondary-400);
    min-height: 4rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover {
      border-color: var(--lp-secondary-400);
    }

    &.lp-select__control--is-focused {
      border-color: var(--lp-primary);
      box-shadow: none;
    }

    .lp-select__value-container {
      padding: 0.2rem 0.6rem;

      .lp-select__placeholder {
        color: var(--lp-secondary);
      }

      .lp-select__single-value {
        color: var(--lp-text);
      }

      &.lp-select__value-container--is-multi {
        .lp-select__multi-value {
          background: var(--lp-secondary-200);

          .lp-select__multi-value__label {
            color: var(--lp-text);
          }
        }
      }
    }

    .lp-select__indicators {
      .lp-select__indicator-separator {
        display: none;
      }

      .lp-select__indicator {
        color: var(--lp-secondary-400);

        &.lp-select__clear-indicator {
          padding: 0.8rem 0.4rem;

          &:hover {
            svg {
              color: var(--lp-danger);
              fill: var(--lp-danger);
            }
          }
        }
      }
    }
  }

  &.lp-select--is-disabled {
    .lp-select__control {
      background-color: var(--lp-secondary-200);

      .lp-select__value-container {
        .lp-select__single-value {
          color: var(--lp-secondary);
        }
      }
    }
  }

  .lp-select__menu {
    .lp-select__menu-list {
      .lp-select__option {
        color: var(--lp-text);
        font-family: var(--lp-font-sans-serif);
        font-size: var(--lp-body-font-size);
        line-height: 1.5;
        // padding: 0.4rem 0.8rem;

        &.lp-select__option--is-selected {
          background-color: var(--lp-primary);
          color: var(--lp-white);
          font-family: var(--lp-font-sans-serif);

          &.lp-select__option--is-focused {
            background-color: var(--lp-primary);
            color: var(--lp-white);
          }

          .lp-select-color-option {
            > span {
              &.busy {
                border: 0.1rem solid var(--lp-background);
              }
            }
          }
        }

        &.lp-select__option--is-focused {
          background-color: var(--lp-primary-250);
          color: var(--lp-text);
          font-family: var(--lp-font-sans-serif);
        }
      }
    }
  }

  &.invalid {
    .lp-select__control {
      border-color: var(--lp-danger);

      &:hover,
      &.lp-select__control--is-focused {
        border-color: var(--lp-danger);
      }

      .lp-select__indicators {
        .lp-select__indicator {
          color: var(--lp-danger);

          &.lp-select__dropdown-indicator {
            &:hover {
              svg {
                color: var(--lp-danger);
                fill: var(--lp-danger);
              }
            }
          }
        }
      }
    }
  }
}

.lp-select__menu-portal {
  .lp-select__menu-list {
    .lp-select__option {
      color: var(--lp-text);
      font-family: var(--lp-font-sans-serif);
      font-size: 1.4rem;
      line-height: 1.5;

      &.lp-select__option--is-selected {
        background-color: var(--lp-primary);
        color: var(--lp-white);
        font-family: var(--lp-font-sans-serif);

        &.lp-select__option--is-focused {
          background-color: var(--lp-primary);
          color: var(--lp-white);
        }
      }

      &.lp-select__option--is-focused {
        background-color: var(--lp-primary-250);
        color: var(--lp-text);
        font-family: var(--lp-font-sans-serif);
      }
    }
  }
}

/* Matter File Select */
.lp-matter-file-select {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
  position: relative;

  > .lp-select {
    max-width: calc(100% - 3.2rem);
    width: 100%;
  }

  + .lp-dropzone {
    margin-top: 1.6rem;
  }

  &:not(:has(button)) {
    > .lp-select {
      max-width: 100%;
    }
  }
}

.lp-matter-file-select-custom-dropdown {
  .lp-select__option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 1.6rem;
    cursor: default;

    .lp-matter-file-select-custom-dropdown-date {
      flex-shrink: 0;
      color: var(--lp-secondary);
      font-size: 1.4rem;
      font-weight: 500;
    }
  }
}