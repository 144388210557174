/* Emails */
.lp-emails-form {
  display: flex;
  flex-direction: column;
  height: calc(100% + 1.6rem);
  margin: -1.6rem;

  .lp-emails-filter {
    padding: 0.8rem 1.6rem;
  }

  .lp-emails-list {
    height: 100%;
    overflow-y: auto;

    + .justify-content-between {
      padding: 1.6rem 1.6rem 0 1.6rem;
    }
  }

  .lp-emails-pagination {
    margin: 0;
    padding: 0.8rem 1.6rem;

    + .justify-content-between {
      padding: 0 1.6rem 0.8rem;
    }
  }
}

.lp-emails-matter {
  padding: 0.8rem 1.6rem;
}

.lp-emails-filter {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.8rem;
  width: 100%;

  .form-control,
  .lp-select {
    width: calc(100% - 7rem);
  }

  .lp-emails-filter-col {
    flex: auto;
  }
}

.lp-emails-pagination {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1.6rem 0;

  .lp-emails-current-page {
    color: var(--lp-secondary);
    font-size: 1.4rem;
  }

  .btn {
    font-size: 1.4rem;
    padding: 0.4rem 0.8rem;
  }
}
