/* Quick Search Panel */
.lp-quick-search-panel {
  background-color: var(--lp-white);
  border-right: 0.1rem solid var(--lp-secondary-400);
  flex-direction: column;
  height: 100%;
  position: relative;

  .lp-quick-search-header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1.6rem;
  }

  .lp-quick-search-selected-filter {
    font-size: 1.4rem;
    color: var(--lp-text);
    margin-top: -1rem;
    padding: 0 1.6rem 1rem 1.6rem;
  }

  .lp-quick-search-title {
    color: var(--lp-primary);
    font-size: 2rem;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .lp-quick-search-actions {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
    justify-content: flex-end;

    .btn-icon {
      padding: 0.4rem;
    }
  }

  .lp-quick-search-searchbar {
    position: relative;

    input {
      background-color: rgba(var(--lp-secondary-200-rgb), 0.5);
      border-color: var(--lp-secondary-200);
      border-radius: 0;
      border-left: 0 none;
      border-right: 0 none;
      font-size: 1.4rem;
      line-height: 2.2rem;
      padding: 0.8rem 4.8rem 0.8rem 1.6rem;

      &:focus {
        border-color: var(--lp-secondary-200);
      }

      &::placeholder {
        color: var(--lp-secondary);
        opacity: 1;
      }
      
      &:-ms-input-placeholder {
        color: var(--lp-secondary);
      }
      
      &::-ms-input-placeholder {
        color: var(--lp-secondary);
      }

      &[disabled] {
        opacity: 0.5;
      }
    }

    .btn-icon {
      padding: 0.4rem;
      position: absolute;
      right: 1.6rem;
      top: 0.8rem;
    }
  }

  .lp-quick-search-list {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-x: hidden;
    overflow-y: overlay;
    position: relative;
    width: 100%;

    @-moz-document url-prefix() {
      overflow-y: auto;
    }
  }

  .lp-quick-search-list-item {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 1.6rem;
    padding: 0.8rem 1.6rem;
    width: 100%;

    + .lp-quick-search-list-item {
      border-top: 0.1rem solid var(--lp-secondary-200);
    }

    .lp-quick-matter-rating {
      svg {
        font-size: 2.4rem;
      }
    }

    .lp-quick-matter-number {
      color: var(--lp-text);
      font-size: 1.4rem;
    }

    .lp-quick-matter-name {
      color: var(--lp-secondary);
      font-size: 1.2rem;
    }

    &.active {
      background-color: rgba(var(--lp-primary-rgb), 0.05);
    }
  }

  .lp-quick-search-pagination {
    color: var(--lp-secondary);
    font-size: 1.2rem;
    padding: 1.6rem;
  }

  .lp-quick-search-panel-toggle {
    align-items: center;
    background-color: var(--lp-white);
    border: 0.1rem solid var(--lp-secondary-400);
    border-radius: 2rem;
    bottom: 7rem;
    color: var(--lp-secondary);
    cursor: pointer;
    display: flex;
    font-size: 2rem;
    height: 2.4rem;
    justify-content: center;
    left: calc(100% - 1.2rem);
    position: absolute;
    width: 2.4rem;
  }

  &.expanded {
    min-width: 22rem;
    width: 22rem;
  }

  &.collapsed {
    cursor: pointer;
    min-width: 5rem;
    width: 5rem;

    .lp-quick-search-header {
      padding: 1.6rem 1rem;
    }

    .lp-quick-search-title {
      letter-spacing: -0.3rem;
      margin-top: 0.3rem;
      text-orientation: upright;
      writing-mode: vertical-lr;
    }

    .lp-quick-search-actions,
    .lp-quick-search-searchbar,
    .lp-quick-search-list,
    .lp-quick-search-empty,
    .lp-quick-search-pagination {
      display: none;
    }
  }
}
