/* Entity Linked */
.lp-linked-entity-content {
  min-height: 10rem;
  position: relative;

  .accordion {
    border-radius: 0.8rem;

    > .card {
      border-radius: 0.8rem;
      min-height: 0;

      + .card {
        margin-top: 0.8rem;
      }

      > .card-header {
        background: var(--lp-gray-100);
        border-bottom: 0.1rem solid transparent;
        border-radius: 0.8rem;
        margin-bottom: 0;
        padding: 0.8rem 1.6rem;
        transition: border-color 0.15s ease-in-out, border-radius 0.15s ease;

        &.opened {
          border-bottom-color: var(--lp-secondary-200);
          border-radius: 0.8rem 0.8rem 0 0;
        }

        > .btn-icon {
          background-color: var(--lp-secondary-400);
          border-radius: 0.4rem;
          color: var(--lp-text);
          padding: 0.6rem;
          text-align: center;
          width: auto;
        }
      }
    }

    .card {
      .card-header {
        .title {
          align-items: center;
          display: flex;
          gap: 0.8rem;

          .btn-icon {
            font-size: 1.2rem;
            padding: 0.4rem;
          }
        }

        .name {
          font-size: 2rem;
          font-weight: 600;
          white-space: nowrap;
        }
      }
    }
  }
}
