/* Charge Rate */
.lp-charge-rate-item {
  background: rgba(var(--lp-secondary-200-rgb), 0.25);
  border: 0.1rem solid var(--lp-secondary-400);
  border-radius: 0.4rem;
  margin-top: 1.6rem;
  padding: 0.8rem;
  position: relative;
  width: 100%;

  .delete-item {
    position: absolute;
    right: -0.1rem;
    top: -0.1rem;

    &.btn-icon {
      border-radius: 0 0.4rem 0 0.4rem;
      padding: 0.4rem;
    }
  }

  .lp-label-value {
    .lp-label {
      color: var(--lp-text);
    }
  }

  .billed-icon {
    align-items: center;
    color: var(--lp-background);
    display: flex;
    font-size: 1rem;
    font-weight: 700;
    justify-content: center;
    padding: 0 0.4rem;
    position: absolute;
    text-transform: uppercase;
    top: -0.1rem;
  }

  .billed-icon {
    background: var(--lp-primary);
    border-radius: 0 0.4rem;
    right: -0.1rem;
  }

  &.read-only {
    .lp-label-value {
      .lp-label {
        color: var(--lp-secondary);
      }
    }
  }
}
