/* Xero Error Page */
.lp-xero-error {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100vh; /* Fallback for browsers which cannot support Custom Properties */
  height: var(--vh, 1vh);
  justify-content: center;
  width: 100%;

  .lp-xero-error-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;

    img {
      height: auto;
      max-height: 50vh;
      max-width: 100%;
      width: auto;
    }
  }

  .lp-xero-error-text {
    height: 100%;
    padding: 4rem 2.4rem 2.4rem;
    text-align: center;
    width: 100%;

    .btn {
      display: inline-block;
      margin-top: 0.6rem;
      width: auto;
    }
  }
}
