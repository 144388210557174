/* Login Box */
.lp-login-box {
  margin: 4.8rem auto;

  .lp-msal-login-btn {
    align-items: center;
    background: var(--lp-white);
    border-radius: 0.8rem;
    border: 0.1rem solid var(--lp-secondary-400);
    box-shadow: 0 0 1rem 0.3rem rgba(var(--lp-text-rgb), 0.05);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    max-width: 30rem;
    padding: 2.4rem;
    text-align: center;
    transition: box-shadow 0.4s ease-in-out;
    width: 100%;

    &:hover {
      box-shadow: 0 0 1rem 0.5rem rgba(var(--lp-text-rgb), 0.1);
    }

    img {
      display: block;
      height: 4rem;
      margin-bottom: 2.4rem;
      width: auto;
    }

    span {
      color: var(--lp-gray);
      font-size: 1.4rem;
      font-weight: 500;
    }
  }

  .lp-errors {
    background-color: rgba(var(--lp-danger-rgb), 0.05);
    border: 0.1rem solid rgba(var(--lp-danger-rgb), 0.15);
    border-radius: 0.4rem;
    color: var(--lp-danger);
    font-size: 1.4rem;
    height: auto;
    margin-top: 2.4rem;
    margin-bottom: 0;
    max-height: 20rem;
    max-width: 50rem;
    overflow-y: auto;
    padding: 0.8rem;
    width: 100%;
    word-break: break-word;
  }
}
