/* Dropzone */
.lp-dropzone {
  background-color: rgba(var(--lp-primary-rgb), 0.05);
  border: 0.1rem dashed rgba(var(--lp-primary-rgb), 0.3);
  border-radius: 0.8rem;
  padding: 2.4rem;
  position: relative;
  text-align: center;
  transition: box-shadow 0.4s ease-in-out, background-color 0.4s ease-in-out;

  &:hover {
    background-color: rgba(var(--lp-primary-rgb), 0.1);
    box-shadow: 0 0 0.5rem 0.5rem rgba(var(--lp-primary-rgb), 0.1);
  }

  input {
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  > svg {
    color: var(--lp-primary-400);
    fill: var(--lp-primary);
    font-size: 6rem;
  }

  > p {
    font-size: 1.6rem;
    margin: 0.8rem 0 0;

    label {
      color: var(--lp-primary);
      font-weight: 700;
    }
  }
}

/* Upload list items */
.lp-upload-list {
  margin-top: 1.6rem;
  padding: 0;

  .lp-upload-list-item {
    align-items: center;
    background: var(--lp-white);
    border: 0.1rem solid var(--lp-secondary-400);
    border-radius: 0.4rem;
    display: flex;
    flex-wrap: nowrap;
    font-size: 1.2rem;
    justify-content: space-between;
    height: auto;
    padding: 0.4rem 0.4rem 0.4rem 0.8rem;
    transition: 0.4s ease-in-out;

    + .lp-upload-list-item {
      margin-top: 0.4rem;
    }

    &:hover {
      background-color: rgba(var(--lp-secondary-rgb), 0.1);
    }

    .name {
      color: var(--lp-text);
      margin-right: 1.6rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .size {
      color: var(--lp-secondary);
      margin: 0 1.6rem 0 auto;
      white-space: nowrap;
    }

    .btn-icon {
      padding: 0.4rem;
    }
  }
}
