/* Template field */
.lp-template-field-item {
  background: var(--lp-background);
  border: 0.1rem solid var(--lp-secondary-400);
  border-radius: 0.4rem;
  padding: 0.8rem;
  width: 100%;

  + .lp-template-field-item {
    margin-top: 1.6rem;
  }

  .lp-label-value {
    + .lp-label-value {
      margin-top: 1.6rem;
    }
  }
}
