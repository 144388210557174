/* Label Value Field */
.lp-label-value {
  .lp-label {
    color: var(--lp-secondary);
  }

  .lp-value {
    align-items: center;
    color: var(--lp-text);
    display: flex;
    flex-wrap: wrap;
    white-space: break-spaces;

    .btn-icon {
      font-size: 1.4rem;
      margin-left: 0.8rem;
      padding: 0.4rem;
    }

    .positive-value {
      color: var(--lp-success);
      margin-left: 0.4rem;
    }

    .negative-value {
      color: var(--lp-danger);
      margin-left: 0.4rem;
    }

    > svg {
      color: var(--lp-success);
      font-size: 1.8rem;
      margin-left: 0.4rem;
    }
  }

  &.highlighted {
    .lp-value {
      color: var(--lp-primary-alt);
      font-weight: 500;
    }
  }

  &.unknown,
  &.free,
  &.tentative,
  &.busy,
  &.out-of-office,
  &.working-elsewhere {
    .lp-value {
      &:before {
        border-radius: 2rem;
        content: '';
        display: block;
        height: 1.6rem;
        margin-right: 0.8rem;
        width: 1.6rem;
      }
    }
  }

  &.unknown {
    .lp-value {
      &:before {
        background: var(--lp-secondary);
      }
    }
  }

  &.free {
    .lp-value {
      &:before {
        background: var(--lp-success);
      }
    }
  }

  &.tentative {
    .lp-value {
      &:before {
        background: var(--lp-info);
      }
    }
  }

  &.busy {
    .lp-value {
      &:before {
        background: var(--lp-primary);
      }
    }
  }

  &.out-of-office {
    .lp-value {
      &:before {
        background: var(--lp-danger);
      }
    }
  }

  &.working-elsewhere {
    .lp-value {
      &:before {
        background: var(--lp-primary-alt);
      }
    }
  }
}
