/* Grid List */
.lp-list-grid {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;

  li {
    align-items: center;
    border-bottom: 0.1rem solid var(--lp-secondary-200);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    &:last-child {
      border-bottom: 0 none;

      &:first-child {
        border-bottom: 0.1rem solid var(--lp-secondary-200);
      }
    }

    &.lp-list-grid-selected {
      background-color: var(--lp-primary-250);

      .lp-list-grid-item-actions {
        .btn-icon {
          &:not(.btn-danger) {
            background-color: var(--lp-white);
          }
        }
      }
    }
  }

  .lp-list-grid-item-name,
  .lp-list-grid-item-type {
    max-width: 100%;
    padding: 0.8rem;
    position: relative;
    text-align: left;
    width: 100%;

    svg {
      font-size: 2rem;
      margin-left: 0.8rem;
      vertical-align: text-bottom;
    }
  }

  .lp-list-grid-item-name {
    padding-left: 1.6rem;
  }

  .lp-list-grid-item-type {
    width: 60%;

    .delegate-list & {
      width: 20rem;
    }
  }

  .lp-list-grid-item-actions {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    min-width: 12.4rem;
    padding: 0.6rem 1.6rem 0.6rem 0.8rem;
    width: 12.4rem;

    .btn-icon {
      padding: 0.6rem;

      + .btn-icon {
        margin-left: 0.8rem;
      }
    }

    a + .btn-icon {
      margin-left: 0.8rem;
    }
  }
}

/* Simple List */
.lp-simple-list {
  display: flex;
  flex-direction: column;
  margin: 0 -1.6rem;

  ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  li {
    align-items: center;
    border-bottom: 0.1rem solid var(--lp-secondary-200);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.4rem 1.6rem;
    width: 100%;

    &:last-child {
      border-bottom: 0 none;
    }
  }

  .lp-simple-list-actions {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .btn-icon {
      line-height: 1;
      padding: 0.6rem;

      + .btn-icon {
        margin-left: 0.8rem;
      }
    }
  }
}


.lp-account-transaction-entry-list {
  display: flex;
  flex-direction: column;
  margin: 0 -1.6rem;

  ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  li {
    align-items: stretch;
    border-bottom: 0.1rem solid var(--lp-secondary-200);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .lp-account-transaction-entry-list-account {
      width: 60%;
      border-right: 0.1rem solid var(--lp-secondary-200);
      padding: 0.4rem 1.6rem;

      &.total {
        font-weight: bold;
        text-align: right;
      }
    }
  
    .lp-account-transaction-entry-list-amount {
      width: 20%;
      //text-align: right;
      border-right: 0.1rem solid var(--lp-secondary-200);
      padding: 0.4rem 1.6rem;

      &:last-child {
        border-right: none;
      }
    }
  }

  .lp-account-transaction-entry-list-header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    text-align: center;
    font-weight: 600;

    .lp-account-transaction-entry-list-account {
      width: 60%;
      border-right: 0.1rem solid var(--lp-primary);
      border-top: 0.1rem solid var(--lp-primary);
      border-bottom: 0.1rem solid var(--lp-primary);
      padding: 0.4rem 1.6rem;
    }
  
    .lp-account-transaction-entry-list-amount {
      width: 20%;
      border-right: 0.1rem solid var(--lp-primary);
      border-top: 0.1rem solid var(--lp-primary);
      border-bottom: 0.1rem solid var(--lp-primary);
      padding: 0.4rem 1.6rem;

      &:last-child {
        border-right: none;
      }
    }

    &.primary {
      background-color: var(--lp-primary-250);
      border-color: var(--lp-primary-400) transparent;
    }
  
    &.secondary {
      background: var(--lp-primary-alt-250);
      border-color: var(--lp-primary-alt-400) transparent;
    }
  }
}