/* Slide-in Panel */
.lp-slide-panel-container {
  align-items: stretch;
  bottom: 0;
  display: flex;
  flex-direction: row;
  height: 100vh; /* Fallback for browsers which cannot support Custom Properties */
  height: var(--vh, 1vh);
  justify-content: flex-end;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  transition: 0.4s ease-in-out;
  width: 100vw;
  
  &.hide {
    z-index: -1;
  }

  &.show {
    z-index: 1210;
  }
}

.lp-slide-panel-overlay {
  background-color: rgba(var(--lp-black-rgb), 0.40);
  height: 100vh; /* Fallback for browsers which cannot support Custom Properties */
  height: var(--vh, 1vh);
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: 0.4s ease-in-out;
  visibility: hidden;
  width: 100%;

  .show & {
    opacity: 1;
    visibility: visible;
  }
}

.lp-slide-panel {
  --lp-panel-width: 80rem;

  background-color: var(--lp-white);
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100vw;
  overflow: hidden;
  position: relative;
  transition: right 0.4s ease-in-out;
  width: var(--lp-panel-width);

  .hide & {
    right: calc(-1 * var(--lp-panel-width));
  }

  .show & {
    right: 0;
  }

  .lp-errors {
    max-width: 100%;
  }
}

.lp-slide-panel-title {
  align-items: center;
  border-bottom: 0.1rem solid var(--lp-secondary-200);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.6rem;
  position: relative;

  h4 {
    margin: 0;
    overflow: hidden;
    padding-right: 1.6rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
}

.lp-slide-panel-title-btns {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  justify-content: flex-end;
}

.lp-slide-panel-pin,
.lp-slide-panel-close {
  align-items: center;
  background-color: var(--lp-secondary-200);
  border-radius: 0.4rem;
  cursor: pointer;
  display: flex;
  font-size: 2.4rem;
  justify-content: center;
  padding: 0.4rem;
  transition: background-color 0.4s ease-in-out;

  &:hover {
    background-color: var(--lp-secondary-400);
  }
}

.lp-slide-panel-pin {
  padding: 0.8rem;

  svg {
    font-size: 2rem;
  }
}

.lp-slide-panel-more-button {
  > button {
    &:not(.btn-danger) {
      background-color: var(--lp-secondary-200);
      transition: background-color 0.4s ease-in-out;
  
      &:hover {
        background-color: var(--lp-secondary-400);
      }
    }
  }
}

.lp-slide-panel-content {
  height: 100%;
  overflow-y: overlay;
  padding: 1.6rem;
  position: relative;

  @-moz-document url-prefix() {
    overflow-y: auto;
  }

  &:has(.lp-loader) {
    position: static;
  }

  hr {
    border-color: var(--lp-secondary);
    margin-left: -1.6rem;
    margin-right: -1.6rem;
  }
}

.lp-iframe-panel {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  iframe {
    border: 0.1rem solid var(--lp-secondary-400);
    border-radius: 0.8rem;
  }
}

.lp-slide-panel-footer {
  .lp-slide-panel-navigation {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  .lp-slide-panel-navigation-button {
    align-items: center;
    background-color: var(--lp-primary);
    color: var(--lp-white);
    cursor: pointer;
    display: flex;
    font-size: 1.4rem;
    justify-content: center;
    line-height: 2rem;
    padding: 0.5rem;
    transition: background-color 0.4s ease-in-out;
    width: 100%;
    
    &.lp-panel-nav-right {
      border-left: 0.1rem solid var(--lp-primary-700);
    }
  
    &:hover {
      background-color: var(--lp-primary-700);
    }
  
    &.disabled {
      cursor: not-allowed;
      opacity: 0.35;
  
      &:hover {
        background-color: var(--lp-primary-550);
      }
    }

    svg {
      font-size: 2rem;
    }
  }
}
