.tiptap {
  border: 1px solid var(--lp-secondary-400);
  border-top: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  outline: 0;
  padding: 1rem 1rem 0 1rem;
  min-height: 200px;
  max-height: 400px;
  overflow-y: auto;

  :first-child {
    margin-top: 0;
  }

  ul, 
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;
    
    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  h1, h2, h3 {
    line-height: 1.3;
    margin-top: 1.6rem;
    margin-bottom: 1.2rem;
    text-wrap: pretty;
  }

  h1 { 
    font-size: 1.4em; 
  }

  h2 { 
    font-size: 1.2em; 
  }

  h3 { 
    font-size: 1.1em; 
  }

  hr {
    margin-left: 0;
    margin-right: 0;
  }

  a {
    text-decoration: underline;
  }

  .ProseMirror-selectednode::selection,
  .ProseMirror-selectednode *::selection {
    background: var(--lp-text);
    color: var(--lp-light);
    text-shadow: none;
  }
}

.tiptap-html-textarea {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--lp-secondary-400);
  border-radius: 4px;

  .html-textarea-menu {
    border-bottom: 1px solid var(--lp-secondary-400);

    select {
      padding: 9px 0 9px 4px;
      border: 0;
      outline: 0;
      border-radius: 4px;
      cursor: pointer;
    }

    select:hover {
      background-color: var(--lp-gray-200);
    }

    option {
      background-color: var(--lp-white);
    }
  }

  textarea {
    border: 0;
    outline: 0;
    min-height: 200px;
    max-height: 400px;
    field-sizing: content;
    padding: 1rem 1rem 0 1rem;
  }
}