/* Preview Panel */
.lp-preview-panel-container {
  height: 100%;

  .lp-preview-panel {
    height: 100%;

    &:has(.lp-preview-panel-navigation) {
      .lp-preview-panel-content {
        height: calc(100% - 87px);
      }
    }
  }
}

.lp-preview-panel-title {
  align-items: center;
  border-bottom: 0.1rem solid var(--lp-border-color-translucent);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.2rem;
  position: relative;

  h4 {
    margin: 0;
    overflow: hidden;
    padding-right: 1.6rem;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
}

.lp-preview-panel-title-btns {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  justify-content: flex-end;
}

.lp-preview-panel-close {
  align-items: center;
  background-color: var(--lp-secondary-200);
  border-radius: 0.4rem;
  cursor: pointer;
  display: flex;
  font-size: 2.4rem;
  justify-content: center;
  padding: 0.4rem;
  transition: background-color 0.4s ease-in-out;

  &:hover {
    background-color: var(--lp-secondary-400);
  }
}

.lp-preview-panel-more-button {
  > button {
    &:not(.btn-danger) {
      background-color: var(--lp-secondary-200);
      transition: background-color 0.4s ease-in-out;
  
      &:hover {
        background-color: var(--lp-secondary-400);
      }
    }
  }
}

.lp-preview-panel-content {
  height: calc(100% - 57px);
  overflow-y: overlay;
  padding: 1.6rem;
  position: relative;

  @-moz-document url-prefix() {
    overflow-y: auto;
  }

  &:has(.lp-loader) {
    position: static;
  }

  hr {
    border-color: var(--lp-secondary);
    margin-left: -1.6rem;
    margin-right: -1.6rem;
  }
}

.lp-iframe-panel {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  iframe {
    border: 0.1rem solid var(--lp-secondary-400);
    border-radius: 0.8rem;
  }
}

.lp-preview-panel-footer {
  .lp-preview-panel-navigation {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  .lp-preview-panel-navigation-button {
    align-items: center;
    background-color: var(--lp-primary);
    color: var(--lp-white);
    cursor: pointer;
    display: flex;
    font-size: 1.4rem;
    justify-content: center;
    line-height: 2rem;
    padding: 0.5rem;
    transition: background-color 0.4s ease-in-out;
    width: 100%;
    
    &.lp-panel-nav-right {
      border-left: 0.1rem solid var(--lp-primary-700);
    }
  
    &:hover {
      background-color: var(--lp-primary-700);
    }
  
    &.disabled {
      cursor: not-allowed;
      opacity: 0.35;
  
      &:hover {
        background-color: var(--lp-primary-550);
      }
    }

    svg {
      font-size: 2rem;
    }
  }
}

.lp-preview-panel-options {
  display: flex;
  flex-direction: column;

  button {
    background-color: var(--lp-white);
    color: var(--lp-text);
    transition: 0.4s ease-in-out;
    position: relative;
    border-radius: 0;

    &:hover {
      color: var(--lp-text);
      background-color: var(--lp-secondary-200);
    }

    &.disabled {
      opacity: 1;
      cursor: none;
      background-color: var(--lp-white);
      color: var(--lp-secondary-600);
    }

    &:not(:last-child)::after {
      background-color: var(--lp-secondary-200);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      content: '';
      height: 0.1rem;
      width: 100%;
    }
  }
}
