/* Global custom style */
* { 
  margin: 0;
  padding: 0;
}

html,
body {
  -moz-osx-font-smoothing: antialiased;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  overscroll-behavior-y: contain;
  text-rendering: optimizeLegibility;
}

html {
  height: 100%;
}

body,
#root {
  min-height: 100vh; /* Fallback for browsers which cannot support Custom Properties */
  min-height: var(--vh, 1vh);
}

/* Scrollbars */
::-webkit-scrollbar {
  -webkit-appearance: none;
  background: transparent;
  height: 0.8rem;
  width: 0.8rem;
}

::-webkit-scrollbar-track {
  background-color: rgba(var(--lp-secondary-rgb), 0.25);
  border-radius: 0.4rem;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(var(--lp-secondary-rgb), 0.5);
  border-radius: 0.4rem;
}

@-moz-document url-prefix() {
  * {
    scrollbar-color: rgba(var(--lp-secondary-rgb), 0.5) rgba(var(--lp-secondary-rgb), 0.25);
    scrollbar-width: thin;
  }
}

/* Text selection */
::selection {
  background: var(--lp-text);
  color: var(--lp-light);
  text-shadow: none;
}

.lp-activity-deleted {
  opacity: 0.5;
  text-decoration: line-through;
  font-style: italic;
}

/* Layout rows spacing */
.row {
  + .row {
    margin-top: 1.6rem;
  }

  &.multiple {
    --lp-gutter-y: 1.6rem;
  }

  &.full-height {
    height: 100%;
  }

  &.templates {
    row-gap: 1.6rem;
  }
}

/* Required labels */
.form-label {
  &.required {
    &:after {
      color: var(--lp-danger);
      content: "*";
      margin-left: 0.4rem;
    }
  }
}

/* Inputs */
.form-control,
.form-select {
  &.invalid {
    border-color: var(--lp-danger);
  }
}

/* Buttons */
.lp-body-content,
.lp-slide-panel {
  .btn {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    white-space: nowrap;

    svg {
      margin-right: 0.4rem;
    }

    &.btn-icon {
      padding: 0.8rem;

      svg {
        margin-right: 0;
      }
    }

    &.btn-danger {
      &:hover {
        color: var(--lp-white);
      }

      &.disabled {
        color: var(--lp-white);
      }
    }

    &.btn-light {
      color: var(--lp-success);
      padding: 0.8rem 0;
    }
  }

  .lp-slide-panel-sticky-bottom {
    background-color: var(--lp-gray-100);
    border-top: 0.1rem solid var(--lp-gray-200);
    bottom: -1.6rem;
    margin: auto -1.6rem -1.6rem;
    padding: 1.6rem;
    position: sticky;
    z-index: 20;

    .col.flex-wrap {
      gap: 0.8rem;
    }
  }
}

.btn {
  &.btn-danger {
    color: var(--lp-white);

    &:hover {
      color: var(--lp-white);
    }
  }

  &.dropdown-toggle {
    &:after {
      display: none;
    }

    svg {
      .show & {
        transform: rotate(180deg);
      }
    }
  }
}

/* Tabs */
.react-tabs {
  -webkit-tap-highlight-color: transparent;
  height: calc(100% - 5.6rem);
  display: flex;
  flex-direction: column;

  .react-tabs__tab-list {
    border-bottom: 0.1rem solid var(--lp-secondary-400);
    margin: 0 -2.4rem;
    padding: 0 2.4rem;

    .react-tabs__tab {
      border-bottom: 0.2rem solid transparent;
      bottom: -0.1rem;
      color: var(--lp-secondary);
      cursor: pointer;
      display: inline-block;
      font-size: 1.4rem;
      list-style: none;
      padding: 0.8rem 1.6rem;
      position: relative;

      &.react-tabs__tab--selected {
        border-color: var(--lp-primary);
        color: var(--lp-primary);
        font-weight: 700;
      }

      &.react-tabs__tab--disabled {
        color: var(--lp-secondary-400);
        cursor: default;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .react-tabs__tab-panel {
    display: none;
    margin: 0 -2.4rem -2.4rem -2.4rem;
    padding: 1.6rem 2.4rem 2.4rem 2.4rem;

    &.react-tabs__tab-panel--selected {
      display: flex;
      flex-direction: column;

      &.lp-summary-boxes-expanded {
        .row:has(.with-grid),
        .row:has(.with-preview) {
          min-height: 40rem;

          .card.with-grid {
            min-height: 37rem;
          }

          .card.with-preview {
            min-height: 37rem;
          }

          .grid {
            min-height: 28rem;

            .ag-root-wrapper {
              min-height: 28rem;
            }
          }
        }
      }
    }
  }
}

/* Cards */
.card {
  max-height: 100%;
  min-height: 10rem;
  position: relative;

  .card-header {
    align-items: center;
    border-bottom: 0 none;
    display: flex;
    justify-content: space-between;
    margin-bottom: -0.8rem;
    padding-top: 0.8rem;

    .form-select {
      margin-right: 0.8rem;
      max-width: 30rem;
    }

    .btn {
      margin-left: auto;
      white-space: nowrap;
    }
  }

  .card-body {
    height: auto;
    max-height: 100%;
    position: relative;
  }

  &.with-grid {
    height: 100%;

    .card-body {
      height: 100%;
      overflow-y: hidden;
      padding: 0;
      display: flex;
      flex-direction: column;
    }
  }

  &.with-preview {
    .card-body {
      padding: 0;
    }
  }

  &.with-calendar {
    &:not(.dashboard) {
      height: 100%;
    }
  }

  &.with-list {
    height: 100%;

    .card-header {
      align-items: center;
      background-color: var(--lp-background);
      border-radius: 0.4rem 0.4rem 0 0;
      display: flex;
      font-size: 1.2rem;
      font-weight: 700;
      margin-bottom: 0;
      padding: 1.4rem 1.6rem;
      text-transform: uppercase;

      .lp-list-grid-parent-selected {
        color: var(--lp-success);
      }

      > div {
        &:first-child {
          border-radius: 0.4rem 0 0 0;

          &:last-child {
            border-radius: 0.4rem 0.4rem 0 0;
          }
        }

        &:last-child {
          border-radius: 0 0.4rem 0 0;
        }
      }
    }

    .card-body {
      height: 100%;
      max-height: 20rem;
      overflow-y: overlay;
      padding: 0;

      @-moz-document url-prefix() {
        overflow-y: auto;
      }
    }
  }

  &.contact-list {
    .card-header {
      > div {
        width: 100%;

        &:nth-child(2) {
          &:not(:last-child) {
            width: 60%;
          }
        }

        &:nth-child(3) {
          min-width: 10.4rem;
          width: 10.4rem;
        }
      }
    }
  }

  &.managers-list {
    .card-body {
      height: 100%;
      max-height: 100%;
      overflow-x: hidden;
      overflow-y: overlay;
      padding: 1.6rem 1.6rem 0;

      @-moz-document url-prefix() {
        overflow-y: auto;
      }

      .lp-simple-list-name {
        width: 100%;
      }

      .lp-actions-btns {
        width: 5rem;
      }
    }

    &.shared {
      .lp-simple-list {
        margin-top: -1.6rem;
      }

      .lp-color-title {
        border-radius: 0.4rem 0.4rem 0 0;
        border-top: 0 none;
      }
    }
  }

  &.delegate-list {
    .card-header {
      > div {
        width: 100%;

        &.lp-list-grid-item-type,
        &.lp-list-grid-item-type {
            width: 20rem;
        }

        &.lp-list-grid-item-actions {
          min-width: 10.4rem;
          width: 10.4rem;
        }
      }
    }
  }
}

/* Selectable Lists */
.lp-box-list {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  height: 100%;
  margin: -1.6rem -1.6rem 0;
  max-height: calc(100% - 4rem);
  overflow-y: auto;
  padding: 1.6rem;
  position: relative;

  &.auto {
    height: auto;
    margin: 0;
    max-height: unset;
    overflow-y: initial;
    padding: 0 0 1.6rem;
  }

  .lp-box-list-item {
    align-items: flex-start;
    border: 0.1rem solid var(--lp-secondary-400);
    border-radius: 0.4rem;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.8rem;
    justify-content: space-between;
    padding: 1.6rem 0.8rem 0.8rem;
    position: relative;
    transition: .2s ease-in-out;

    .lp-label-value {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      font-size: 1.4rem;
      width: calc(50% - 0.8rem);

      .lp-label {
        max-width: 15rem;
        min-width: 3rem;
        word-break: break-all;
        
        &:after {
          content: ":";
          margin-right: 0.8rem;
        }
      }

      .lp-value {
        white-space: normal;
        word-break: break-all;
      }
    }

    .lp-box-list-full-row {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
      width: 100%;

      .lp-label-value {
        width: 100%;
      }
    }

    .delete-item {
      border-radius: 0 0.4rem;
      padding: 0.4rem;
      position: absolute;
      right: -0.1rem;
      top: -0.1rem;
    }

    .unselected-icon,
    .selected-icon {
      align-items: center;
      background: var(--lp-background);
      border-radius: 50%;
      bottom: -0.4rem;
      display: flex;
      font-size: 2.4rem;
      height: 2.4rem;
      justify-content: center;
      position: absolute;
      right: -0.4rem;
      transition: .2s ease-in-out;
      width: 2.4rem;
    }

    .type-icon,
    .existing-icon {
      align-items: center;
      color: var(--lp-background);
      display: flex;
      font-size: 1rem;
      font-weight: 700;
      justify-content: center;
      padding: 0 0.4rem;
      position: absolute;
      text-transform: uppercase;
      top: -0.1rem;
    }

    .type-icon {
      border-radius: 0.4rem 0;
      left: -0.1rem;

      &.sent {
        background: var(--lp-sent-mail);
        color: var(--lp-text);
      }

      &.received {
        background: var(--lp-received-mail);
        color: var(--lp-background);
      }

      &.date {
        background: var(--lp-primary);
        color: var(--lp-background);
      }
    }

    .existing-icon {
      background: var(--lp-primary);
      border-radius: 0 0.4rem;
      right: -0.1rem;

      + .unselected-icon {
        display: none;
      }
    }

    .unselected-icon {
      color: var(--lp-secondary);
      opacity: 0;
      visibility: hidden;
    }

    .selected-icon {
      color: var(--lp-success);
    }

    &.existing {
      background: rgba(var(--lp-primary-rgb), 0.05);
      pointer-events: none;
    }

    &.selected {
      background: rgba(var(--lp-success-rgb), 0.05);
      border-color: var(--lp-success);
      box-shadow: 0 0 0.5rem 0.3rem rgba(var(--lp-success-rgb), 0.5);
    }

    &.read-only {
      cursor: default;
    }

    &:not(.selected) {
      &:hover {
        box-shadow: 0 0 0.5rem 0.3rem rgba(var(--lp-text-rgb), 0.1);
  
        .unselected-icon {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &.draft-mail {
      background: rgba(var(--lp-secondary-200-rgb), 0.5);
      cursor: default;

      &:hover {
        box-shadow: none;
      }

      .unselected-icon,
      .selected-icon {
        display: none;
      }
    }
  }

  &.with-pagination {
    max-height: calc(100% - 10.1rem);
  }
}

/* Empty with image */
.lp-empty {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  opacity: 0.5;
  padding: 0.8rem;

  .image {
    background-image: url(../../assets/images/empty.webp);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 13rem;
    width: 17rem;
  }

  .text {
    color: var(--lp-secondary);
    font-size: 1.4rem;
  }

  &.list {
    .image {
      height: 7rem;
      width: 11rem;
    }
  }

  &.emails {
    .image {
      height: 15rem;
      width: 21rem;
    }
  
    .text {
      font-size: 1.8rem;
    }
  }
}

/* Empty without image */
.lp-list-empty {
  color: var(--lp-secondary);
  font-size: 1.4rem;
  font-style: italic;

  &.case-manager {
    margin-top: -0.8rem;
    margin-bottom: 0.8rem;
  }

  &.managers-contact {
    padding: 0.4rem 0;
  }
}

/* Errors */
.lp-errors {
  background-color: rgba(var(--lp-danger-rgb), 0.05);
  border: 0.1rem solid rgba(var(--lp-danger-rgb), 0.15);
  border-radius: 0.4rem;
  color: var(--lp-danger);
  font-size: 1.4rem;
  height: auto;
  margin: 1.6rem auto;
  min-height: 4rem;
  max-height: 15rem;
  max-width: calc(100% - 3.2rem);
  overflow-y: auto;
  padding: 0.8rem;
  width: 100%;
  word-break: break-word;

  &.calendar-errors {
    margin-bottom: 1.6rem;
    max-width: 100%;
  }
}

.lp-error {
  color: var(--lp-danger);
  display: block;
  font-size: 1.4rem;

  &.form-text {
    color: var(--lp-danger);
    font-size: 1.4rem;
    margin-top: 0;
  }
}

/* Inline Radio Group */
.lp-inline-radio-group {
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;

  .form-check-inline {
    align-items: center;
    border: 0.1rem solid var(--lp-secondary-400);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    min-height: 2rem;
    padding: 0.8rem 1.6rem;
    width: 100%;

    &:first-child {
      border-radius: 0.4rem 0 0 0.4rem;

      &:last-child {
        border-radius: 0.4rem;
      }
    }

    &:last-child {
      border-radius: 0 0.4rem 0.4rem 0;
    }

    + .form-check-inline {
      border-left: 0 none;
    }

    .form-check-label {
      color: var(--lp-primary);
      cursor: pointer;
      line-height: 2.2rem;
    }

    .form-check-input {
      border: 0.1rem solid var(--lp-secondary-600);
      cursor: pointer;
      display: flex;
      float: none;
      margin: 0 0.8rem 0 0;
      vertical-align: unset;
    }
  }

  > div {
    + .form-label {
      margin-left: 0.8rem;
    }
  }
}

/* React Date Picker */
.react-datepicker-wrapper {
  .react-datepicker__input-container {
    input {
      appearance: none;
      background-clip: padding-box;
      background-color: var(--lp-white);
      border: 0.1rem solid var(--lp-secondary-400);
      border-radius: 0.4rem;
      box-shadow: none;
      color: var(--lp-text);
      display: block;
      font-size: var(--lp-body-font-size);
      line-height: 1.5;
      padding: 0.7rem 0.8rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      width: 100%;

      &.invalid {
        border-color: var(--lp-danger);
      }

      &:focus {
        background-color: var(--lp-white);
        border-color: var(--lp-primary);
        box-shadow: none;
        color: var(--lp-text);
        outline: 0;
      }

      &.disabled,
      &[disabled],
      &:disabled {
        background-color: var(--lp-secondary-200);
      }
    }
  }
}

.react-datepicker-popper {
  .react-datepicker {
    border-color: var(--lp-primary);
    border-radius: 0.4rem;
    color: var(--lp-text);
    font-family: var(--lp-font-sans-serif);
    font-size: 1.4rem;

    .react-datepicker__triangle {
      border-bottom-color: var(--lp-primary) !important;

      &:before,
      &:after {
        border-bottom-color: var(--lp-primary) !important;
      }
    }

    .react-datepicker__navigation {
      line-height: 1;
      top: 0.3rem;

      &.react-datepicker__navigation--next--with-time {
        right: 12.2rem;
      }

      > span {
        &:before {
          border-color: var(--lp-white);
          color: var(--lp-primary);
        }
      }
    }

    .react-datepicker__month-container {
      border-radius: 0.4rem;
      min-width: 20rem;

      .react-datepicker__header {
        background-color: var(--lp-primary);
        border-color: var(--lp-primary);
        border-radius: 0;
        color: var(--lp-white);

        .react-datepicker__current-month {
          color: var(--lp-white);
          font-size: 1.4rem;
          line-height: 1.5;
        }

        .react-datepicker__header__dropdown {
          .react-datepicker__month-dropdown-container {
            .react-datepicker__month-dropdown {
              background-color: var(--lp-white);
              border-color: var(--lp-secondary);
              min-width: 10rem;
              width: auto;

              .react-datepicker__month-option {
                color: var(--lp-text);
                font-size: 1.4rem;

                &:hover {
                  background-color: var(--lp-primary-250);
                  color: var(--lp-primary);
                }

                &.react-datepicker__month-option--selected_month {
                  background-color: var(--lp-primary);
                  color: var(--lp-white);

                  .react-datepicker__month-option--selected {
                    display: none;
                  }
                }
              }
            }

            .react-datepicker__month-read-view {
              .react-datepicker__month-read-view--down-arrow {
                border-color: transparent;
                border-top-color: var(--lp-white);
                border-width: 0.5rem;
                top: 1rem;

                &:before {
                  border-top-color: var(--lp-white);
                }
              }
            }
          }

          .react-datepicker__year-dropdown-container {
            margin-left: 3rem;

            .react-datepicker__year-dropdown {
              background-color: var(--lp-white);
              border-color: var(--lp-secondary);
              min-width: 10rem;
              width: auto;

              .react-datepicker__year-option {
                color: var(--lp-text);
                font-size: 1.4rem;

                &:hover {
                  background-color: var(--lp-primary-250);
                  color: var(--lp-primary);
                }

                .react-datepicker__navigation--years {
                  border-color: transparent;
                  border-style: solid;
                  border-width: 0.8rem;
                  display: block;
                  height: 1.4rem;
                  top: auto;
                  width: 1.4rem;
                }

                .react-datepicker__navigation--years-upcoming {
                  border-bottom-color: var(--lp-secondary);
                  margin-bottom: 0.5rem;
                  top: -0.5rem;
                }

                .react-datepicker__navigation--years-previous {
                  border-top-color: var(--lp-secondary);
                  margin-top: 0.5rem;
                  top: 0.5rem;
                }

                &.react-datepicker__year-option--selected_year {
                  background-color: var(--lp-primary);
                  color: var(--lp-white);

                  .react-datepicker__year-option--selected {
                    display: none;
                  }
                }
              }
            }

            .react-datepicker__year-read-view {
              .react-datepicker__year-read-view--down-arrow {
                border-color: transparent;
                border-top-color: var(--lp-white);
                border-width: 0.5rem;
                transform: rotate(0deg);
                top: 1rem;

                &:before {
                  border-top-color: var(--lp-secondary);
                }
              }
            }
          }
        }

        .react-datepicker__day-names {
          .react-datepicker__day-name {
            color: var(--lp-white);
            margin: 0.4rem;
            width: 2.8rem;
          }
        }
      }

      .react-datepicker__week {
        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
          line-height: 2;
          margin: 0.4rem;
        }

        .react-datepicker__day {
          border-radius: 50%;
          color: var(--lp-text);
          height: 2.8rem;
          width: 2.8rem;

          &:hover {
            background-color: var(--lp-primary-250);
            color: var(--lp-primary-850);
          }

          &.react-datepicker__day--keyboard-selected,
          &.react-datepicker__day--selected {
            background: var(--lp-primary);
            border-radius: 50%;
            color: var(--lp-white);
          }

          &.react-datepicker__day--today {
            background: var(--lp-secondary-400);
            border-radius: 50%;
            color: var(--lp-text);

            &.react-datepicker__day--outside-month {
              background: var(--lp-secondary-200);
              color: var(--lp-secondary);
            }
          }

          &.react-datepicker__day--outside-month {
            color: var(--lp-secondary-400);

            &:hover {
              background-color: var(--lp-primary-250);
              color: var(--lp-primary-850);
            }
          }

          &.react-datepicker__day--disabled {
            color: var(--lp-secondary);
            cursor: not-allowed;

            &:hover {
              color: var(--lp-text);
            }
          }
        }
      }
    }

    .react-datepicker__time-container {
      width: 12rem;

      .react-datepicker__header--time {
        background: var(--lp-primary);
        border-radius: 0;
        padding: 0.5rem;

        .react-datepicker-time__header {
          color: var(--lp-background);
          font-size: 1.2rem;
          font-weight: normal;
        }
      }

      .react-datepicker__time {
        .react-datepicker__time-box {
          width: 100%;
        }
      }
    }

    .react-datepicker__month-read-view--down-arrow {
      border-color: var(--lp-secondary);
      border-width: 0.8rem;
      margin-left: 3rem;
      transform: rotate(0deg);
      top: 0.5rem;
    }

    .react-datepicker__input-time-container {
      margin: 0;
      padding: 1rem;

      .react-datepicker-time__caption {
        color: var(--lp-secondary);
      }

      .react-datepicker-time__input-container {
        .react-datepicker-time__input {
          input {
            &.react-datepicker-time__input {
              width: 10rem;
            }
          }
        }
      }
    }
  }
}

/* Phone Input */
.lp-phone-input {
  &.react-tel-input {
    font-family: var(--lp-font-sans-serif);
    font-size: var(--lp-body-font-size);

    .form-control {
      background: var(--lp-white);
      border-color: var(--lp-secondary-400);
      border-radius: 0.4rem;
      color: var(--lp-text);
      font-size: var(--lp-body-font-size);
      font-weight: 400;
      line-height: 1.5;
      padding: 0.7rem 0.8rem 0.7rem 5.6rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      width: 100%;

      &:focus {
        background: var(--lp-white);
        border-color: var(--lp-primary);
        box-shadow: none;
        color: var(--lp-text);
        outline: 0;
      }

      &.invalid-number {
        border-color: var(--lp-danger);

        &:focus {
          box-shadow: none;
        }
      }
    }

    .flag-dropdown {
      border-radius: 0.4rem 0 0 0.4rem;
    }

    .selected-flag {
      border-radius: 0.4rem 0 0 0.4rem;
      padding: 0 0 0 0.8rem;
      width: 4.8rem;

      &:before {
        background-color: var(--lp-secondary-200);
        border: 0.1rem solid transparent;
        border-radius: 0.3rem 0 0 0.3rem;
        bottom: 0.1rem;
        left: 0.1rem;
        top: 0.1rem;
      }

      &:focus,
      &.open {
        &:before {
          border-color: transparent;
          box-shadow: none;
        }
      }
    }

    .country-list {
      background-color: var(--lp-white);
      border: 0.1rem solid var(--lp-secondary-400);
      border-radius: 0.4rem;
      box-shadow: 0 0 0.5rem 0.3rem rgba(var(--lp-text-rgb), 0.1);
      margin: 0.4rem 0 0;
      width: 36.8rem;
    
      .flag {
        left: 0.8rem;
        margin: 0;
        top: 1.2rem;
      }
    
      .divider {
        border-bottom-color: var(--lp-secondary-400);
        margin-bottom: 0.4rem;
        padding-bottom: 0.4rem;
      }
    
      .country {
        padding: 1rem 0.8rem 1rem 4.3rem;

        .country-name {
          margin-right: 0.8rem;
        }
    
        .dial-code {
          color: var(--lp-primary);
        }
    
        &:hover,
        &.highlight {
          background-color: var(--lp-primary-250);
        }
      }
    }

    .invalid-number-message {
      display: none;
    }
  }

  &.invalid {
    .form-control {
      border-color: var(--lp-danger);
    }
  }
}

/* Tooltips */
body .tooltip {
  white-space: pre-line;

  &.info {
    .tooltip-arrow:before {
      border-right-color: var(--lp-info);
    }

    .tooltip-inner {
      background-color: var(--lp-info);
      color: var(--lp-text);
    }
  }

  &.warning {
    .tooltip-arrow:before {
      border-right-color: var(--lp-warning);
    }

    .tooltip-inner {
      background-color: var(--lp-warning);
      color: var(--lp-text);
    }
  }

  &.error {
    .tooltip-arrow:before {
      border-right-color: var(--lp-danger);
    }

    .tooltip-inner {
      background-color: var(--lp-danger);
    }
  }

  &.linked {
    .tooltip-arrow:before {
      border-right-color: var(--lp-primary-alt);
    }

    .tooltip-inner {
      background-color: var(--lp-primary-alt);
    }
  }
}

/* Accordions */
.accordion {
  .accordion-item {
    border-radius: 0.8rem;

    + .accordion-item {
      margin-top: 1.6rem;
    }

    &:first-of-type,
    &:last-of-type {
      border-radius: 0.4rem;

      .accordion-button {
        border-radius: 0.4rem 0.4rem 0 0;

        &.collapsed {
          border-radius: 0.4rem;
        }
      }
    }

    .accordion-button {
      border-radius: 0.4rem 0.4rem 0 0;

      &:before {
        background-image: var(--lp-accordion-btn-icon);
        background-repeat: no-repeat;
        background-size: var(--lp-accordion-btn-icon-width);
        content: "";
        flex-shrink: 0;
        height: var(--lp-accordion-btn-icon-width);
        margin-left: 0;
        margin-right: 0.8rem;
        transition: var(--lp-accordion-btn-icon-transition);
        width: var(--lp-accordion-btn-icon-width);
      }

      &:not(.collapsed):before {
        background-image: var(--lp-accordion-btn-active-icon);
        transform: var(--lp-accordion-btn-icon-transform);
      }

      &:after {
        display: none;
      }

      &.collapsed {
        border-radius: 0.4rem;
      }
    }

    .accordion-body {
      background: rgba(var(--lp-secondary-200-rgb), 0.5);
      border-radius: 0 0 0.4rem 0.4rem;
    }

    &.lp-accordion-success {
      .accordion-button {
        background-color: var(--lp-success);
      }

      .accordion-body {
        background-color: rgba(var(--lp-success-rgb), 0.15);
      }
    }

    &.lp-accordion-warning {
      .accordion-button {
        background-color: var(--lp-warning);
      }

      .accordion-body {
        background-color: rgba(var(--lp-warning-rgb), 0.15);
      }
    }

    &.lp-accordion-info {
      .accordion-button {
        background-color: var(--lp-info);
      }

      .accordion-body {
        background-color: rgba(var(--lp-info-rgb), 0.15);
      }
    }

    &.lp-accordion-danger {
      .accordion-button {
        background-color: var(--lp-danger);
        color: var(--lp-white);

        &:before {
          filter: invert(1);
        }
      }

      .accordion-body {
        background-color: rgba(var(--lp-danger-rgb), 0.15);
      }
    }

    &.lp-summary-boxes-accordion {
      border: 0.1rem solid var(--lp-secondary-600);
      margin-bottom: 1.6rem;

      .accordion-button {
        background-color: var(--lp-secondary-400);
        color: var(--lp-text);
      }

      .accordion-body {
        background-color: var(--lp-secondary-200);
        padding: 1.6rem;
      }
    }
  }
}

/* Spinner */
.lp-spinner {
  margin-right: 0.4rem;
}

/* Toast */
.toast-container {
  z-index: 9999 !important;
}

.toast {
  .toast-header {
    font-size: 1.2rem;
    font-weight: 700;
    padding: 0.4rem 0.8rem;

    .btn-close {
      margin-left: auto;
    }
  }

  &.bg-primary,
  &.bg-danger,
  &.bg-dark {
    .toast-header {
      color: var(--lp-background);
    }

    .btn-close {
      filter: invert(1);
      opacity: 1;
    }
  }

  .toast-body {
    background-color: rgba(var(--lp-background-rgb), 0.65);
  }
}

/* Text Title */
.lp-text-title {
  color: var(--lp-secondary);
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  white-space: nowrap;
}

/* Content Colored Title */
.lp-color-title {
  border-style: solid none;
  border-width: 0.1rem 0;
  font-weight: 600;
  padding: 0.4rem 1.6rem;

  &.primary {
    background-color: var(--lp-primary-250);
    border-color: var(--lp-primary-400) transparent;
  }

  &.secondary {
    background: var(--lp-primary-alt-250);
    border-color: var(--lp-primary-alt-400) transparent;
  }

  &.full-width {
    margin: 1.6rem -1.6rem;

    .lp-sticky-bottom & {
      margin-top: 0;
    }
  }

  &.full-width-no-bottom-padding {
    margin: 0 -1.6rem;
  }
}

/* Cancel button on same row */
.lp-cancel-btn.col {
  align-self: flex-end;
  max-width: 10rem;
  width: 10rem;
}

/* Dropdown items */
.dropdown-menu {
  .dropdown-item {
    &.primary {
      background: var(--lp-primary);
      color: var(--lp-background);
    }
  }
}

/* Invoice due date input group */
.lp-invoice-due-date {
  &.input-group {
    > .form-control {
      flex: none;
      min-width: 6.8rem;
    }
  
    .lp-select {
      flex: 1;
  
      .lp-select__control {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

/* Emails */
.lp-emails-list {
  border-top: 0.1rem solid var(--lp-secondary-200);
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  height: 100%;
  padding: 0.8rem 1.6rem;

  + .lp-emails-pagination {
    border-top: 0.1rem solid var(--lp-secondary-200);
    margin-bottom: 0;
  }

  .lp-email-item {
    background: var(--lp-white);
    border-radius: 0.8rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    margin-bottom: 0.8rem;
    padding: 0.8rem 1.6rem;
    transition: all 0.2s;
    position: relative;

    &.sent {
      border-left: 0.4rem solid var(--lp-primary);

      .lp-email-item-icon {
        svg {
          color: var(--lp-primary);
        }
      }
    }

    &.received {
      border-left: 0.4rem solid var(--lp-success);

      .lp-email-item-icon {
        svg {
          color: var(--lp-success);
        }
      }
    }

    &:hover {
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
      border-left-width: 0.8rem;
    }

    .lp-email-item-wrapper {
      display: flex;
      align-items: center;
    }

    .lp-email-item-icon {
      margin-right: 1.2rem;
      
      svg {
        font-size: 2.4rem;
      }
    }

    .lp-email-item-content {
      flex: 1;
      min-width: 0;
    }

    .lp-email-item-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.4rem;
    }

    .lp-email-item-subject {
      font-size: 1.6rem;
      line-height: 1;
      font-weight: 500;
      margin: 0;
      color: var(--lp-text);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: flex;
      align-items: center;

      .lp-email-item-existing,
      .lp-email-item-draft {
        background-color: var(--lp-secondary-200);
        color: rgba(var(--lp-text-rgb), 0.75);
        border-radius: 0.8rem;
        font-size: 1.2rem;
        margin-right: 0.4rem;
        padding: 0.2rem 0.8rem;
        transition: .2s ease-in-out;

        &:hover {
          background: rgba(var(--lp-secondary-rgb), 0.3);
        }
      }
    }

    .lp-email-item-subject.lp-email-manual-add {
      .lp-email-item-existing,
      .lp-email-item-draft {
        background: rgba(var(--lp-secondary-rgb), 0.3);
      }
    }

    .lp-email-item-time {
      display: flex;
      align-items: center;
      color: var(--lp-secondary);
      font-size: 1.2rem;
      white-space: nowrap;

      svg {
        font-size: 1.2rem;
        margin-right: 0.4rem;
      }

      span {
        margin-left: 0.4rem;
      }
    }

    .lp-email-item-details {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .lp-email-item-recipients {
      font-size: 1.4rem;
      color: var(--lp-secondary);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      > div > span:first-child {
        margin-right: 0.4rem;
      }
    }

    .lp-email-item-actions {
      display: flex;
      gap: 0.8rem;
      margin-left: 0.8rem;
      flex-shrink: 0;

      svg {
        color: var(--lp-secondary);
        font-size: 1.4rem;
      }
    }

    .lp-email-item-body-preview {
      font-size: 1.4rem;
      line-height: 1;
      margin-top: 0.4rem;
      overflow: hidden;
      display: -webkit-box;
      line-clamp: 1;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    &.existing-email {
      background: rgba(var(--lp-primary-rgb), 0.05);
      pointer-events: none;

      .lp-email-item-selection {
        display: none;
      }
    }

    &.selected-email {
      background: rgba(var(--lp-success-rgb), 0.05);
      box-shadow: 0 0 0.5rem 0.3rem rgba(var(--lp-success-rgb), 0.5);
    }

    .lp-email-item-selection {
      > span {
        align-items: center;
        background: var(--lp-background);
        border-radius: 50%;
        bottom: -0.4rem;
        display: flex;
        font-size: 2rem;
        height: 2rem;
        justify-content: center;
        position: absolute;
        right: -0.4rem;
        transition: .2s ease-in-out;
        width: 2rem;

        &.unselected {
          color: var(--lp-secondary);
          opacity: 0;
          visibility: hidden;
        }
    
        &.selected {
          color: var(--lp-success);
        }
      }
    }
  }
}

.lp-appointments-list {
  .lp-appointment-item {
    padding-top: 2.2rem;
    padding-bottom: 1.2rem;

    .lp-appointments-item-info {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 1rem;
      background: var(--lp-text);
      color: var(--lp-background);
      padding: 0.1rem 0.8rem;
      font-weight: 700;
      border-top-left-radius: 0.8rem;
      border-bottom-right-radius: 0.6rem;
    }
  
    .lp-appointments-item-date {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 1rem;
      background: var(--lp-primary);
      color: var(--lp-background);
      padding: 0.1rem 0.8rem;
      font-weight: 700;
      border-top-right-radius: 0.8rem;
      border-bottom-left-radius: 0.6rem;
    }
  }
}

.lp-button-grid-tooltip,
.lp-email-recipient-tooltip {
  background: var(--lp-secondary-200);
  border: 0.1rem solid var(--lp-secondary-400);
  border-radius: 0.4rem;
  box-shadow: 0 0 0.5rem 0.3rem rgba(var(--lp-text-rgb), 0.1);
  color: var(--lp-text);
  font-size: 1.2rem;
  line-height: 1.5;
  padding: 0.4rem 0.8rem;
  word-break: break-word;
  z-index: 99;
}

.lp-email-view {
  height: 100%;

  .row + .row {
    margin-top: 1.2rem;
  }

  .lp-label-value {
    display: flex;
    gap: 0.8rem;
  }

  .lp-email-view-subject {
    .lp-value {
      display: flex;
      align-items: center;
      gap: 0.4rem;

      .lp-email-view-matter,
      .lp-email-view-unread {
        background-color: var(--lp-secondary-200);
        color: rgba(var(--lp-text-rgb), 0.75);
        border-radius: 0.8rem;
        font-size: 1.2rem;
        font-weight: 500;
        padding: 0.2rem 0.8rem;
        transition: .2s ease-in-out;
    
        &:hover {
          background: rgba(var(--lp-secondary-rgb), 0.3);
        }
      }
    }
  }
}

.lp-email-chips {
  .lp-value {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.4rem;
    white-space: break-spaces;

    .name {
      color: var(--lp-text);
      font-weight: normal;
      white-space: nowrap;
    }

    .email {
      align-items: center;
      background: var(--lp-secondary-200);
      border-radius: 0.8rem;
      color: rgba(var(--lp-text-rgb), 0.75);
      display: flex;
      font-size: 1.4rem;
      justify-content: center;
      padding: 0.2rem 0.8rem;
      transition: .2s ease-in-out;
      word-break: break-all;

      &:hover {
        background: rgba(var(--lp-secondary-rgb), 0.3);
      }
    }
  }
}

/* Chips */
.lp-chip {
  align-items: center;
  border-radius: 0.4rem;
  color: var(--lp-background);
  display: inline-flex;
  font-size: 1rem;
  font-weight: 700;
  justify-content: center;
  line-height: 1.2;
  padding: 0.4rem 0.8rem;
  text-transform: uppercase;

  &.boolean {
    min-width: 3.6rem;
  }

  &.conflict {
    min-width: 6.1rem;
  }

  &.invoice-status {
    min-width: 12rem;

    .lp-value & {
      min-width: 0;
    }
  }

  &.success {
    background: var(--lp-success);
    color: var(--lp-background);
  }

  &.warning {
    background: var(--lp-warning);
    color: var(--lp-text);
  }

  &.danger {
    background: var(--lp-danger);
    color: var(--lp-background);
  }

  &.partial {
    background: var(--lp-pink);
    color: var(--lp-text);
  }

  &.secondary {
    background: var(--lp-secondary-200);
    color: var(--lp-secondary);
  }
}

