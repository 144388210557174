/* Client Ledger Select */
.card.with-grid {
  .card-body {
    .lp-client-ledger-select {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: 0.8rem;
      justify-content: flex-start;
      left: 0.8rem;
      position: absolute;
      top: 0.8rem;
      z-index: 8;

      .lp-select {
        min-width: 30rem;
      }
    }
  }
}