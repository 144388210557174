/* Welcome Page */
.lp-welcome-title {
  text-align: center;

  h1 {
    margin-bottom: 0.8rem;
  }

  .email {
    background: var(--lp-primary);
    border-radius: 0.4rem;
    color: var(--lp-background);
    font-weight: 600;
    margin-bottom: 2.4rem;
    padding: 0.4rem 0.8rem;
  }

  p {
    margin-bottom: 1.6rem;
  }
}

.lp-welcome-form {
  max-width: 96.4rem;
  width: 100%;
}
