/* Participating Entity */
.lp-entity-profile {
  align-items: center;
  border-bottom: 0.1rem solid var(--lp-secondary-200);
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin: 0 -1.5rem;
  padding: 0 1.6rem 1.6rem;
  position: relative;

  .lp-entity-photo {
    align-items: center;
    background-color: var(--lp-warning);
    border-radius: 0.8rem;
    color: var(--lp-text);
    display: flex;
    flex-direction: row;
    font-size: 3.2rem;
    font-weight: 700;
    height: 7.2rem;
    justify-content: center;
    position: relative;
    width: 7.2rem;
  }

  .lp-entity-status {
    align-items: center;
    border-radius: 50%;
    bottom: -0.6rem;
    display: flex;
    height: 2rem;
    justify-content: center;
    position: absolute;
    right: -0.6rem;
    width: 2rem;

    svg {
      color: var(--lp-white);
      fill: var(--lp-white);
      font-size: 1.4rem;
    }

    &.active {
      background-color: var(--lp-success);
    }

    &.inactive {
      background-color: var(--lp-danger);
    }
  }

  .lp-entity-name {
    font-size: 1.8rem;
    font-weight: 700;
  }

  .lp-entity-role {
    background-color: var(--lp-primary);
    border-radius: 1.6rem;
    color: var(--lp-white);
    font-size: 1.4rem;
    padding: 0.2rem 0.8rem;
  }

  .lp-entity-actions {
    align-items: center;
    border: 0.1rem solid var(--lp-secondary-600);
    border-radius: 0 0.4rem 0 0.4rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    right: -0.1rem;
    top: -1.7rem;

    .btn-icon {
      &:first-child {
        border-radius: 0 0 0 0.4rem;

        &:last-child {
          border-left: 0 none;
          border-radius: 0 0.4rem 0 0.4rem;
        }
      }

      &:last-child {
        border-left: 0.1rem solid var(--lp-secondary-600);
        border-radius: 0 0.4rem 0 0;
      }

      &:hover {
        background: var(--lp-secondary-200);
      }
    }
  }
}

.lp-entity-info {
  border-bottom: 0.1rem solid var(--lp-secondary-200);
  display: flex;
  flex-direction: column;
  margin: 0 -1.6rem;
  padding: 1.6rem;
}

.lp-entity-contact {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 1.6rem 0;

  .lp-entity-contact-item {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 0.8rem;

    svg {
      color: var(--lp-primary-alt);
      font-size: 2.4rem;
      min-width: 2.4rem;
    }

    .email {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

/* Bails */
.lp-bails {
  border-top: 0.1rem solid var(--lp-secondary-400);
  margin: 0 -1.6rem;
  padding: 1.6rem 1.6rem 0;
}

.lp-bail-conditions {
  .lp-title {
    border-top: 0.1rem solid var(--lp-secondary-400);
    margin: 0 -1.6rem 0.8rem;
    padding: 0.4rem 1.6rem;
    width: auto;
  }
}

.lp-bail-condition-item {
  background: rgba(var(--lp-secondary-200-rgb), 0.25);
  border: 0.1rem solid var(--lp-secondary-400);
  border-radius: 0.4rem;
  padding: 0.8rem;
  position: relative;
  width: 100%;

  .delete-item {
    position: absolute;
    right: -0.1rem;
    top: -0.1rem;

    &.btn-icon {
      border-radius: 0 0.4rem 0 0.4rem;
      padding: 0.4rem;
    }
  }
}

.lp-handcuffed {
  svg {
    color: var(--lp-primary-alt);
    fill: var(--lp-primary-alt);
    font-size: 2rem;
    margin-right: 0.8rem;

    &.default-icon {
      color: var(--lp-success);
      fill: var(--lp-success);
      margin: 0 0 0 0.8rem;
    }
  }
}

.lp-entity-bails {
  margin: 0 -1.6rem;

  .lp-entity-bail-status {
    .lp-entity-bail-status-title {
      background-color: var(--lp-primary-250);
      border-style: solid none;
      border-width: 0.1rem 0;
      border-color: var(--lp-primary-400) transparent;
      font-weight: 600;
      padding: 0.4rem 1.6rem;
    }

    .lp-entity-bail-status-content {
      padding: 0.8rem 1.6rem;
    }

    .lp-entity-bail-status-date {
      color: var(--lp-secondary);
      font-size: 1.2rem;
      line-height: 1;
    }
  }

  .lp-entity-bail-condition {
    .lp-entity-bail-condition-title {
      background-color: var(--lp-primary-alt-250);
      border-style: solid none;
      border-width: 0.1rem 0;
      border-color: var(--lp-primary-alt-400) transparent;
      font-weight: 600;
      padding: 0.4rem 1.6rem;
    }
  }
}


// li {
//   align-items: center;
//   border-bottom: 0.1rem solid var(--lp-secondary-200);
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   padding: 0.4rem 1.6rem;
//   width: 100%;

//   &:last-child {
//     border-bottom: 0 none;
//   }
// }
