/* HTML Custom Table */
table.lp-custom-table {
  border: 0.1rem solid var(--lp-secondary-400);
  width: 100%;

  thead {
    tr {
      background: var(--lp-secondary-200);
      border-bottom: 0.1rem solid var(--lp-secondary-400);
      color: var(--lp-text);
      font-size: 1.4rem;
      font-weight: 600;
  
      th {
        padding: 0.8rem;
  
        &:not(:last-child) {
          border-right: 0.1rem solid var(--lp-secondary-400);
        }
      }
    }
  }

  tbody {
    tr {
      background: var(--lp-white);
      border-bottom: 0.1rem solid var(--lp-secondary-200);
      color: var(--lp-text);
      font-size: 1.4rem;
      font-weight: 400;
  
      td {
        padding: 0.8rem;
  
        &:not(:last-child) {
          border-right: 0.1rem solid var(--lp-secondary-200);
        }
      }
  
      &:hover {
        background: var(--lp-gray-100);
      }
    }
  }

  tfoot {
    tr {
      background: var(--lp-gray-100);
      color: var(--lp-text);
      font-size: 1.4rem;
      font-weight: 400;
  
      td {
        padding: 0.8rem;
  
        &:not(:last-child) {
          border-right: 0.1rem solid var(--lp-secondary-200);
        }
      }
    }
  }
}