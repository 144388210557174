.lp-diary-manager-calendar {
  padding: 0;

  .lp-calendar-filters-info {
    align-items: center;
    border-bottom: 0.1rem solid var(--lp-secondary-200);
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
    justify-content: flex-start;
    margin-right: auto;
    padding: 0.8rem 1.6rem;
  
    > div {
      color: var(--lp-secondary);
    }
  
    .btn {
      font-size: 1.4rem;
      line-height: 1.4;
      padding: 0.4rem 0.8rem;
    }

    + .calendar-container {
      height: calc(100% - 4.5rem);
    }
  }

  .calendar-container {
    height: 100%;
    padding: 1.6rem;
  }
}
