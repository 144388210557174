.lp-form-error-tooltip {
  background: var(--lp-secondary-200);
  border: 0.1rem solid var(--lp-secondary-400);
  border-radius: 0.4rem;
  box-shadow: 0 0 0.5rem 0.3rem rgba(var(--lp-text-rgb), 0.1);
  color: var(--lp-text);
  font-size: 1.2rem;
  line-height: 1.5;
  padding: 0.4rem 0.8rem;
  z-index: 99;
}

.lp-form-error-button {
  svg {
    font-size: 1.2em;
    color: var(--lp-red);
  }
}
