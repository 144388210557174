/* Template error */
.lp-template-existing-file {
  background-color: rgba(var(--lp-primary-rgb), 0.05);
  border: 0.1rem solid rgba(var(--lp-primary-rgb), 0.15);
  border-radius: 0.4rem;
  color: var(--lp-primary);
  font-size: 1.4rem;
  height: auto;
  margin: 1.6rem auto;
  max-height: 12rem;
  min-height: 6rem;
  overflow-y: auto;
  padding: 0.8rem;
  width: 100%;
  word-break: break-word;

  span {
    color: var(--lp-primary);
    cursor: pointer;
    text-decoration: underline;
  }
}
