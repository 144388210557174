/* ====================
  LawPage Custom Theme File
==================== */
/* Color system */
$white:      #FFF;
$black:      #000;
$light:      #F9F9F9;
$dark:       #19141E;
$gray-200:   #E9E9E9;
$gray-400:   #D4D4D4;
$gray-600:   #BEBEBE;
$gray:       #939393;
$indigo-250: #CFD0E0;
$indigo-400: #B2B3CD;
$indigo-550: #9597BA;
$indigo-700: #797BA8;
$indigo-850: #5C5E95;
$indigo:     #3F4282;
$orange-250: #F0DDCC;
$orange-400: #E7C8AD;
$orange-550: #DFB48E;
$orange-700: #D69F70;
$orange-850: #CD8B51;
$orange:     #C47632;
$blue:       #4BB3FD;
$green:      #52B788;
$yellow:     #F9DC5C;
$red:        #F15152;
$pink:       #FFC0CB;

$colors: (
  "white":      $white,
  "black":      $black,
  "light":      $light,
  "dark":       $dark,
  "gray":       $gray,
  "indigo":     $indigo,
  "orange":     $orange,
  "blue":       $blue,
  "green":      $green,
  "yellow":     $yellow,
  "red":        $red,
  "pink":       $pink,
);

$primary:         $indigo;
$primary-250:     $indigo-250;
$primary-400:     $indigo-400;
$primary-550:     $indigo-550;
$primary-700:     $indigo-700;
$primary-850:     $indigo-850;
$primary-alt:     $orange;
$primary-alt-250: $orange-250;
$primary-alt-400: $orange-400;
$primary-alt-550: $orange-550;
$primary-alt-700: $orange-700;
$primary-alt-850: $orange-850;
$secondary:       $gray;
$secondary-200:   $gray-200;
$secondary-400:   $gray-400;
$secondary-600:   $gray-600;
$success:         $green;
$info:            $blue;
$warning:         $yellow;
$danger:          $red;
$text:            $dark;
$background:      $light;

$theme-colors: (
  "primary":         $primary,
  "primary-250":     $primary-250,
  "primary-400":     $primary-400,
  "primary-550":     $primary-550,
  "primary-700":     $primary-700,
  "primary-850":     $primary-850,
  "primary-alt":     $primary-alt,
  "primary-alt-250": $primary-alt-250,
  "primary-alt-400": $primary-alt-400,
  "primary-alt-550": $primary-alt-550,
  "primary-alt-700": $primary-alt-700,
  "primary-alt-850": $primary-alt-850,
  "secondary":       $secondary,
  "secondary-200":   $secondary-200,
  "secondary-400":   $secondary-400,
  "secondary-600":   $secondary-600,
  "success":         $success,
  "info":            $info,
  "warning":         $warning,
  "danger":          $danger,
  "text":            $text,
  "background":      $background,
);

/* Activity Type Custom Colors */
:root {
  --lp-recordable-event:    #2C5545;
  --lp-user-note:           #BEBEBE;
  --lp-system-note:         #939393;
  --lp-microsoft-word:      #2F559C;
  --lp-microsoft-excel:     #257347;
  --lp-microsoft-powerpoint:#CE4C34;
  --lp-microsoft-onenote:   #723786;
  --lp-csv:                 #955F20;
  --lp-pdf:                 #F40F02;
  --lp-text-file:           #26252D;
  --lp-image:               #E1CC4F;
  --lp-audio-video:         #D36E70;
  --lp-archive:             #C6A664;
  --lp-html-file:           #E34C26;
  --lp-email-file:          #3F888F;
  --lp-other-file:          #CAC4B0;
  --lp-received-mail:       #9D9101;
  --lp-sent-mail:           #84C3BE;
  --lp-sms:                 #924E7D;
  --lp-whatsapp:            #25D366;
  --lp-appointment:         #8F8B66;
  --lp-disbursement:        #DE4C8A;
  --lp-incidental-expense:  #4FAFE4;
  --lp-invoice:             #697A21;
  --lp-other:               #CAC4B0;
}

/* Prefix for :root CSS variables */
$prefix: lp-;

/* Options */
$enable-rfs:     false;
$enable-shadows: true;

/* Spacing */
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.4,
  2: $spacer * 0.8,
  3: $spacer * 1.2,
  4: $spacer * 1.6,
  5: $spacer * 2.4,
);

/* Body */
$body-bg:    $background;
$body-color: $text;

/* Links */
$link-color:      $primary-alt;
$link-decoration: none;

/* Grid */
$grid-gutter-width: 1.6rem;
$grid-breakpoints: (
  xs:        0,
  sm:    576px,
  md:    768px,
  lg:    992px,
  xl:   1200px,
  xxl:  1600px
);

/* Components */
$border-width:             0.1rem;
$border-style:             solid;
$border-color:             $gray-400;
$border-color-translucent: null;
$border-radius:            0.4rem;
$box-shadow:               none;
$box-shadow-inset:         none;
$component-active-color:   $white;
$component-active-bg:      $primary;

/* Typography */
$font-family-sans-serif: "Inter", system-ui, -apple-system, "Segoe UI", "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-size-root:         62.5%;
$font-size-base:         1.6rem;
$h1-font-size:           4rem;
$h2-font-size:           3.2rem;
$h3-font-size:           2.8rem;
$h4-font-size:           2.4rem;
$h5-font-size:           2rem;
$h6-font-size:           1.6rem;
$headings-margin-bottom: $spacer * 1.6;
$headings-font-weight:   700;
$headings-line-height:   1.25;

/* Tooltips */
$tooltip-font-size:     1.2rem;
$tooltip-max-width:     20rem;
$tooltip-color:         $white;
$tooltip-bg:            $primary;
$tooltip-border-radius: $border-radius;
$tooltip-opacity:       0.9;
$tooltip-padding-y:     $spacer * 0.4;
$tooltip-padding-x:     $spacer * 0.8;
$tooltip-arrow-width:   1.2rem;
$tooltip-arrow-height:  0.6rem;
$zindex-tooltip:        1250;

/* Form fields, buttons, labels */
$input-padding-y:               0.7rem;
$input-padding-x:               0.8rem;
$input-btn-focus-width:         0;
$input-btn-focus-color-opacity: 1;
$input-btn-focus-box-shadow:    none;
$btn-box-shadow:                none;
$form-label-margin-bottom:      0;
$input-focus-border-color:      $component-active-bg;
$form-select-indicator-color:   $gray-600;
$btn-padding-y:                 .8rem;
$btn-padding-x:                 1.6rem;
$btn-border-width:              0;
$input-bg:                      $white;
$form-switch-color:             $gray-600;
$btn-disabled-opacity:          0.5;

/* Cards */
$card-box-shadow:    none;
$card-spacer-y:      $spacer * 1.6;
$card-spacer-x:      $spacer * 1.6;
$card-height:        auto;
$card-cap-padding-y: 0;
$card-cap-padding-x: $card-spacer-x * 0.5;
$card-cap-bg:        transparent;

/* Modals */
$modal-inner-padding:               $spacer * 1.6;
$modal-footer-margin-between:       $modal-inner-padding * 0.5;
$modal-dialog-margin:               0;
$modal-dialog-margin-y-sm-up:       0;
$modal-title-line-height:           $headings-line-height;
$modal-content-color:               $text;
$modal-content-bg:                  $white;
$modal-content-border-color:        var(--lp-gray-200);
$modal-content-border-width:        $border-width;
$modal-backdrop-bg:                 $black;
$modal-backdrop-opacity:            0.75;
$modal-header-border-color:         $modal-content-border-color;
$modal-header-border-width:         $modal-content-border-width;
$modal-header-padding-y:            0.8rem;
$modal-header-padding-x:            1.6rem;
$modal-header-padding:              $modal-header-padding-y $modal-header-padding-x;
$modal-footer-border-color:         $modal-header-border-color;
$modal-footer-border-width:         $modal-header-border-width;
$zindex-modal-backdrop:             1500;
$zindex-modal:                      1600;

/* React Date Picker */
$datepicker__background-color:          $white;
$datepicker__border-color:              $gray-400;
$datepicker__highlighted-color:         $orange;
$datepicker__muted-color:               $gray-400;
$datepicker__selected-color:            $indigo;
$datepicker__text-color:                $dark;
$datepicker__header-color:              $white;
$datepicker__navigation-disabled-color: lighten($datepicker__muted-color, 10%);
$datepicker__border-radius:             0.4rem;
$datepicker__day-margin:                0.8rem;
$datepicker__font-size:                 1.4rem;
$datepicker__font-family:               $font-family-sans-serif;
$datepicker__item-size:                 1.4rem;
$datepicker__margin:                    0.4rem;
$datepicker__navigation-button-size:    32px;
$datepicker__triangle-size:             8px;

/* Dropdowns */
$dropdown-min-width:                10rem;
$dropdown-padding-x:                0;
$dropdown-padding-y:                0;
$dropdown-spacer:                   .4rem;
$dropdown-font-size:                1.4rem;
$dropdown-color:                    $body-color;
$dropdown-bg:                       $white;
$dropdown-border-color:             transparent;
$dropdown-border-radius:            $border-radius;
$dropdown-border-width:             0;
$dropdown-inner-border-radius:      subtract($dropdown-border-radius, $dropdown-border-width);
$dropdown-divider-bg:               $secondary-200;
$dropdown-divider-margin-y:         $spacer * .8;
$dropdown-box-shadow:               0 0 0.5rem 0.3rem rgba(var(--lp-text-rgb), 0.1);
$dropdown-link-color:               $body-color;
$dropdown-link-hover-color:         $dropdown-link-color;
$dropdown-link-hover-bg:            rgba(var(--lp-secondary-rgb), 0.1);
$dropdown-link-active-color:        $body-color;
$dropdown-link-active-bg:           transparent;
$dropdown-link-disabled-color:      $gray-600;
$dropdown-item-padding-y:           $spacer * .8;
$dropdown-item-padding-x:           $spacer * 1.6;

/* Accordion */
$accordion-padding-y:                 0.8rem;
$accordion-padding-x:                 1.6rem;
$accordion-color:                     var(--#{$prefix}body-color);
$accordion-bg:                        $white;
$accordion-border-width:              0;
$accordion-border-color:              transparent;
$accordion-border-radius:             0;
$accordion-inner-border-radius:       0;
$accordion-transition:                0.15s ease-in-out;
$accordion-button-active-bg:          $white;
$accordion-button-active-color:       $body-color;
$accordion-button-focus-border-color: transparent;
$accordion-button-focus-box-shadow:   none;
$accordion-icon-transition:           none;
$accordion-icon-width:                1.6rem;

/* Toasts */
$toast-max-width:                   35rem;
$toast-padding-x:                   0.8rem;
$toast-padding-y:                   0.8rem;
$toast-font-size:                   1.4rem;
$toast-color:                       null;
$toast-background-color:            $white;
$toast-border-width:                $border-width;
$toast-border-color:                var(--#{$prefix}border-color-translucent);
$toast-border-radius:               $border-radius;
$toast-box-shadow:                  $box-shadow;
$toast-spacing:                     $toast-padding-x;
$toast-header-color:                $dark;
$toast-header-background-color:     transparent;
$toast-header-border-color:         $toast-border-color;

/* Spinners */
$spinner-width:           1.6rem;
$spinner-height:          $spinner-width;
$spinner-vertical-align:  -.125em;
$spinner-border-width:    0.1rem;
$spinner-animation-speed: .75s;
$spinner-width-sm:        1rem;
$spinner-height-sm:       $spinner-width-sm;
$spinner-border-width-sm: .2em;
