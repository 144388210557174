.tiptap-control-group {
  border: 1px solid var(--lp-secondary-400);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  .button-group {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;

    .button-set {
      display: flex;
      gap: 6px;
    }

    .menu-button {
      border: 0;
      border-radius: 4px;
      padding: 2px 4px;
      font-size: 1.4em;
      background-color: transparent;
    }

    .menu-button:enabled:hover {
      background-color: var(--lp-gray-200);
    }

    .menu-button.is-active {
      background-color: var(--lp-gray-300);
    }

    select {
      padding-left: 4px;
      border: 0;
      outline: 0;
      border-radius: 4px;
      cursor: pointer;
    }

    select:hover {
      background-color: var(--lp-gray-200);
    }

    option {
      background-color: var(--lp-white);
    }

    .heading1 {
      font-size: 1.4em;
      font-weight: 500;
    }
    .heading2 {
      font-size: 1.2em;
      font-weight: 500;
    }
    .heading3 {
      font-size: 1.1em;
      font-weight: 500;
    }
    .paragraph {
      font-size: 1em;
    }
  }
}

.tiptap-color-group {
  display: flex;
  align-items: center;
  position: relative;

  .button-apply {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2px;
    padding: 0 4px;
    border-radius: 4px;
    height: 100%;
    
    .menu-button {
      font-size: 0.9em;
    }

    .current-color-preview {
      height: 4px;
      min-width: 10px;
    }
  }

  .button-apply:hover {
    background-color: var(--lp-gray-200);
  }

  .button-popup-open {
    display: flex;
    align-items: center;
    padding: 0px;
    border-radius: 4px;
    height: 100%;

    .menu-button {
      font-size: 0.7em;
    }
  }

  .button-popup-open:hover {
    background-color: var(--lp-gray-200);
  }

  .tiptap-color-picker {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    padding: 6px;
    top: 37px;
    right: 0px;
    width: 162px;
    border-radius: 4px;
    background-color: var(--lp-gray-100);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
    z-index: 10;

    .color-button {
      width: 20px;
      height: 20px;
      border: 0;
      border-radius: 4px;
    }

    .color-button.white-border {
      border: 1px solid var(--lp-gray-300);
    }
  }
}

.tiptap-link {
  .link-popup-title {
    font-weight: 600;
  }
  .link-popup {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 6px 10px;
    border-radius: 4px;
    background-color: var(--lp-gray-100);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
    z-index: 10;

    button {
      padding: 4px 10px;
    }
  }
  .link-popup-error {
    background-color: var(--lp-danger);
    color: var(--lp-white);
  }
}