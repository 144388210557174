/* Modals */
.lp-dialog {
  .modal-content {
    .modal-header {
      .modal-title {
        font-size: 2rem;
      }

      .btn-close {
        background-color: var(--lp-secondary-200);
        background-size: 1.4rem auto;
        box-sizing: border-box;
        color: var(--lp-text);
        height: 2.8rem;
        opacity: 1;
        padding: 0.4rem;
        width: 2.8rem;
      }
    }

    .modal-body {
      .lp-modal-warning {
        align-items: center;
        display: flex;
        flex-direction: column;

        svg {
          color: var(--lp-warning);
          display: block;
          fill: var(--lp-warning);
          font-size: 3.6rem;
          margin-bottom: 0.8rem;
        }
      }
    }

    .modal-footer {
      padding: 0.4rem;
    }
  }
}
