/* Tabs on mobile */
.lp-tabs-mobile {
  position: relative;
  z-index: 15;

  .input-group {
    .input-group-text {
      background-color: var(--lp-secondary-400);
      border: 0.1rem solid var(--lp-secondary-600);
    }
  
    .lp-select {
      flex: 1;
  
      .lp-select__control {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}
