/* Notifications */
button.lp-notification-btn {
  border-radius: 5rem;
  bottom: 0.8rem;
  font-size: 1.4rem;
  line-height: 1.6rem;
  padding: 0.4rem 0.8rem;
  position: fixed;
  right: 0.8rem;
  z-index: 9999;

  &.btn-success {
    animation: pulse-success 2s infinite;
    box-shadow: 0 0 0 0 var(--lp-success);
  }

  &.btn-warning {
    animation: pulse-warning 2s infinite;
    box-shadow: 0 0 0 0 var(--lp-warning);
  }

  &.btn-info {
    animation: pulse-info 2s infinite;
    box-shadow: 0 0 0 0 var(--lp-info);
  }

  &.btn-danger {
    animation: pulse-danger 2s infinite;
    box-shadow: 0 0 0 0 var(--lp-danger);
  }

  svg {
    font-size: 1.6rem;
    height: 1.6rem;
    width: 1.6rem;
  }
}

.lp-notification-dismiss {
  align-items: center;
  display: flex;
  font-size: 2.4rem;
  height: 2.4rem;
  justify-content: center;
  margin-left: auto;
  width: 2.4rem;
}

@keyframes pulse-success {
  0% {
    box-shadow: 0 0 0 0 rgba(var(--lp-success-rgb), 0.7);
    transform: scale(0.95);
  }
  
  70% {
    box-shadow: 0 0 0 1rem rgba(255, 82, 82, 0);
    transform: scale(1);
  }
  
  100% {
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    transform: scale(0.95);
  }
}

@keyframes pulse-warning {
  0% {
    box-shadow: 0 0 0 0 rgba(var(--lp-warning-rgb), 0.7);
    transform: scale(0.95);
  }
  
  70% {
    box-shadow: 0 0 0 1rem rgba(255, 82, 82, 0);
    transform: scale(1);
  }
  
  100% {
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    transform: scale(0.95);
  }
}

@keyframes pulse-info {
  0% {
    box-shadow: 0 0 0 0 rgba(var(--lp-info-rgb), 0.7);
    transform: scale(0.95);
  }
  
  70% {
    box-shadow: 0 0 0 1rem rgba(255, 82, 82, 0);
    transform: scale(1);
  }
  
  100% {
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    transform: scale(0.95);
  }
}

@keyframes pulse-danger {
  0% {
    box-shadow: 0 0 0 0 rgba(var(--lp-danger-rgb), 0.7);
    transform: scale(0.95);
  }
  
  70% {
    box-shadow: 0 0 0 1rem rgba(255, 82, 82, 0);
    transform: scale(1);
  }
  
  100% {
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    transform: scale(0.95);
  }
}
