/* Preview Email */
.lp-email-action-btns {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  justify-content: flex-start;

  .reverse-icon {
    svg {
      transform: scaleX(-1);
    }
  }
}
