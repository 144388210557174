/* Summary Box */
.lp-summary-box {
  align-items: center;
  background: var(--lp-white);
  border: 0.1rem solid var(--lp-secondary-400);
  border-radius: 0.8rem;
  cursor: default;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: nowrap;
  gap: 1.6rem;
  padding: 2.4rem 1.6rem 1.6rem;
  position: relative;
  transition: all 300ms ease-in-out;

  .text {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
  }

  .item {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
  }

  .value {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
  }

  .icon {
    align-items: center;
    background: var(--lp-primary);
    border-radius: 50%;
    color: var(--lp-white);
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .label {
    color: var(--lp-secondary);
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1;
    margin-right: 0.8rem;
    position: relative;
    text-transform: uppercase;
    white-space: nowrap;

    &:after {
      border-radius: 0.2rem;
      content: '';
      display: block;
      height: 0.2rem;
      left: 0;
      position: absolute;
      top: 100%;
      width: 60%;
    }

    &.lp-primary {
      &:after {
        background: var(--lp-primary);
      }
    }

    &.lp-warning {
      &:after {
        background: var(--lp-warning);
      }
    }

    &.lp-danger {
      &:after {
        background: var(--lp-danger);
      }
    }

    &.lp-primary-250 {
      &:after {
        background: var(--lp-primary-250);
      }
    }

    &.lp-primary-400 {
      &:after {
        background: var(--lp-primary-400);
      }
    }

    &.lp-primary-550 {
      &:after {
        background: var(--lp-primary-550);
      }
    }

    &.lp-primary-700 {
      &:after {
        background: var(--lp-primary-700);
      }
    }

    &.lp-primary-850 {
      &:after {
        background: var(--lp-primary-850);
      }
    }
  }

  .number {
    color: var(--lp-text);
    font-size: 2rem;
    font-weight: 700;
    line-height: 1;
  }

  .hours,
  .minutes {
    color: var(--lp-secondary);
    font-size: 2rem;
    line-height: 1;
  }

  .hours {
    + .number {
      margin-left: 0.4rem;
    }
  }

  .title {
    align-items: center;
    background: var(--lp-primary);
    border-radius: 0.6rem 0;
    color: var(--lp-background);
    display: flex;
    font-size: 1.2rem;
    font-weight: 600;
    left: 0;
    line-height: 1;
    max-width: 100%;
    overflow: hidden;
    padding: 0.2rem 0.8rem;
    position: absolute;
    text-overflow: ellipsis;
    text-transform: uppercase;
    top: 0;
    transition: all 300ms ease-in-out;
    white-space: nowrap;
  }

  &.unbilled {
    .title,
    .icon {
      background: var(--lp-primary-alt);
    }
  }

  &.total-files,
  &.open-matters {
    .title,
    .icon {
      background: var(--lp-success);
    }
  }

  &.profit {
    .title,
    .icon {
      background: var(--lp-info);
    }
  }

  &.unpaid {
    .title,
    .icon {
      background: var(--lp-danger);
    }
  }

  &.billed {
    .title,
    .icon {
      background: var(--lp-warning);
      color: var(--lp-text);
    }
  }

  &.client-balance-warning {
    background-color: var(--lp-danger);

    .title,
    .icon {
      background: var(--lp-white);
      color: var(--lp-danger);
    }

    .value {
      color: var(--lp-white);
    }
  }

  &:hover {
    .title {
      padding-right: 3rem;
    }
  }
}

.lp-doughnut {
  background:
    radial-gradient(var(--lp-white) 40%, transparent 41%),
    conic-gradient(var(--lp-doughnut-gradient));
  border-radius: 50%;
  display: flex;
  height: 7.5rem;
  min-width: 7.5rem;
  width: 7.5rem;
}

.lp-summary-boxes-area {
  display: grid;
  gap: 1.6rem;
  margin-bottom: 1.6rem;
  grid-template-columns: repeat(auto-fit, minmax(calc((100% - 4.8rem) / 4), 1fr));
}

.lp-summary-boxes-placeholder {
  margin-bottom: 0.8rem;
  min-height: 4.5rem;
  position: relative;
}
