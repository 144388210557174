.card.with-calendar.dashboard {
  .card-body {
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  .calendar-date-picker {
    display: flex;
    width: 100%;
    padding: 0.8rem 1.6rem;
    border-bottom: 0.1rem solid var(--lp-secondary-200);

    .calendar-dates {
      display: flex;
      flex-grow: 1;
      justify-content: space-evenly;

      .calendar-date {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0rem 0.8rem;
        flex-grow: 1;
        flex-basis: 100%;
        position: relative;
        font-size: 1.4rem;
        line-height: 1.9rem;
        cursor: pointer;
        transition: 0.3s;
        border-top: 1px solid var(--lp-gray-400);
        border-bottom: 1px solid var(--lp-gray-400);

        &.today {
          background-color: var(--lp-gray-200);
        }

        &.selected {
          background-color: var(--lp-primary);
          color: var(--lp-white);
          border-color: var(--lp-primary);
        }

        &.selected:hover {
          background-color: var(--lp-primary-850);
        }

        &:hover {
          background-color: var(--lp-primary-250);
        }
      }
    }

    .calendar-date-action {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
      padding: 0;

      svg {
        margin: 0 0.4rem;
      }

      &.calendar-date-action-left {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &.calendar-date-action-right {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .calendar-events {
    overflow-y: auto;
    padding-bottom: 0.4rem;
    position: relative;
    height: 100%;

    .calendar-no-events {
      padding: 0.8rem 1.6rem;
    }

    .calendar-events-switch {
      position: absolute;
      right: 1.6rem;
      top: 0.5rem;
      font-size: 1.4rem;
      font-weight: 500;
      color: var(--lp-secondary);
    }

    .calendar-events-by-date {
      padding: 0.4rem 1.6rem;
      display: flex;
      flex-direction: column;
      gap: 0.8rem;

      .calendar-event-upcoming,
      .calendar-event-in-progress {
        padding: 0.4rem 0.8rem;
        background-color: rgba(var(--lp-info-rgb), 0.1);
        color: var(--lp-info);
        border-radius: 0.4rem;
        align-self: flex-start;
        font-size: 1.2rem;
        line-height: 1;

        svg {
          margin-top: -0.4rem;
          margin-right: 0.8rem;
        }
      }

      .calendar-events-date {
        font-weight: 600;
      }

      .calendar-event {
        background: var(--lp-white);
        border-left: 0.4rem solid var(--lp-gray-400);
        border-radius: 0.8rem;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
        cursor: pointer;
        display: flex;
        gap: 0.4rem;
        padding: 0.8rem 1.6rem;
        transition: all 0.2s;

        &.unknown {
          border-color: var(--lp-secondary);
        }
      
        &.free {
          border-color: var(--lp-success);
        }
      
        &.tentative {
          border-color: var(--lp-info);
        }
      
        &.busy {
          border-color: var(--lp-primary);
        }
      
        &.out-of-office {
          border-color: var(--lp-danger);
        }
      
        &.working-elsewhere {
          border-color: var(--lp-primary-alt);
        }

        &.past {
          opacity: 0.5;
        }

        .calendar-event-time {
          display: flex;
          flex-direction: column;
          padding: 0.4rem;
          min-width: 5rem;
          flex-shrink: 0;
          color: var(--lp-secondary);
          font-size: 1.2rem;
          align-items: flex-end;
        }

        .calendar-event-content {
          color: var(--lp-secondary);
          display: flex;
          flex-direction: column;
          padding: 0.4rem;

          .calendar-event-title {
            font-size: 1.6rem;
            line-height: 1;
            font-weight: 500;
            color: var(--lp-text);
          }

          .calendar-event-title-private {
            margin-left: 0.4rem;

            svg {
              margin-top: -0.4rem;
            }
          }
        }

        .calendar-event-join {
          margin-left: auto;
          align-self: center;
        }

        &:hover {
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
          border-left-width: 0.8rem;
        }
      }
    }

    .calendar-events-by-date:has(:is(.calendar-event-in-progress, .calendar-event-upcoming)) :nth-child(1 of .calendar-event) {
      background: rgba(var(--lp-info-rgb), 0.05);
      box-shadow: 0 0 0.5rem 0.3rem rgba(var(--lp-info-rgb), 0.5);
    }
  }
}