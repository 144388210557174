/* AuthLayout Page */
.lp-auth-page {
  align-items: stretch;
  display: flex;
  height: 100%;
  justify-content: center;
  min-height: 100vh; /* Fallback for browsers which cannot support Custom Properties */
  min-height: var(--vh, 1vh);
  overflow: hidden;

  > .row {
    flex: 1 1 100%;
  }

  .lp-auth-page-left {
    background-color: rgba(var(--lp-danger-rgb), 0.2);
    background-image: url(../../assets/images/lp-logo-light-vert.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 50rem;
    opacity: 0.25;
  }

  .lp-auth-page-right {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .lp-theme-toggle {
      background-image: url(../../assets/images/theme-toggle-light.webp);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;
      display: none;  // remove this when implement dark theme
      height: 3.5rem;
      position: absolute;
      right: 1.6rem;
      top: 1.6rem;
      width: 5.8rem;

      .dark & {
        background-image: url(../../assets/images/theme-toggle-dark.webp);
      }
    }

    .mobile-logo {
      img {
        height: 7.5rem;
        width: auto;
      }
    }

    .lp-auth-page-wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      padding: 2.4rem;
      width: 100%;
    }

    .lp-auth-page-title {
      h1 {
        margin-bottom: 0;

        span {
          color: var(--lp-red);
        }
      }
    }
  }

  .lp-copyright {
    color: var(--lp-gray);
    font-size: 1.2rem;
  }
}
