/* Navbar User Info */
.lp-user-container {
  margin-top: auto;
  position: relative;
}

.lp-user-container-item {
  align-items: center;
  border-top: 0.1rem solid var(--lp-primary-850);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 1rem 1.6rem;
  position: relative;

  .delegate & {
    border-top: 0.1rem solid var(--lp-primary-alt-850);
  }

  .lp-user-error {
    color: var(--lp-danger);
    display: flex;
    font-size: 1.8rem;
    position: absolute;
    right: 0;
    top: 0.2rem;
  }
}

.lp-user-container-avatar {
  align-items: center;
  background-color: var(--lp-warning);
  border-radius: 0.4rem;
  display: flex;
  font-weight: 700;
  height: 3.8rem;
  justify-content: center;
  margin-right: 0.8rem;
  min-width: 3.8rem;
  width: 3.8rem;
}

.lp-user-container-info {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.lp-user-container-name {
  color: var(--lp-light);
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lp-user-container-role {
  color: var(--lp-warning);
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
}

.lp-user-container-dropdown {
  background-color: var(--lp-white);
  border-radius: 0.4rem;
  bottom: calc(100% + 1.6rem);
  box-shadow: 0 0 0.5rem 0 rgba(var(--lp-text-rgb), 0.25);
  font-size: 1.4rem;
  left: 1.6rem;
  list-style-type: none;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  visibility: hidden;
  z-index: 10;

  .user-opened & {
    opacity: 1;
    visibility: visible;
  }

  &:after {
    border-top: 1rem solid var(--lp-white);
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    content: "";
    height: 0;
    left: 0.8rem;
    position: absolute;
    top: 100%;
    width: 0; 
  }

  > li {
    white-space: nowrap;

    > a {
      align-items: center;
      color: var(--lp-text);
      display: flex;
      padding: 0.8rem 1.6rem;
      transition: .2s ease-in-out;

      &:hover {
        background-color: var(--lp-primary-250);
      }
    }

    &.lp-user-container-dropdown-email {
      align-items: center;
      background-color: var(--lp-secondary-200);
      border-radius: 0.4rem 0.4rem 0 0;
      color: rgba(var(--lp-text-rgb), 0.75);
      cursor: default;
      display: flex;
      font-weight: 700;
      padding: 0.8rem 1.6rem;
    }

    &.lp-user-container-dropdown-sync {
      align-items: center;
      cursor: pointer;
      display: flex;
      padding: 0.8rem 1.6rem;
      transition: .2s ease-in-out;

      &:hover {
        background-color: var(--lp-primary-250);
      }
    }

    &.lp-user-container-dropdown-change-lte {
      align-items: center;
      cursor: pointer;
      display: flex;
      padding: 0.8rem 1.6rem;
      transition: .2s ease-in-out;

      &:hover {
        background-color: var(--lp-primary-250);
      }
    }

    &.lp-user-container-dropdown-logout {
      align-items: center;
      background-color: rgba(var(--lp-danger-rgb), 0.25);
      border-radius: 0 0 0.4rem 0.4rem;
      color: var(--lp-danger);
      cursor: pointer;
      display: flex;
      padding: 0.8rem 1.6rem;
      transition: .2s ease-in-out;

      &:hover {
        background-color: rgba(var(--lp-danger-rgb), 0.35);
      }
    }

    svg {
      margin-right: 0.8rem;
    }
  }
}
