/* User Profile Card */
.lp-user-profile {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  width: 100%;

  .lp-user-photo {
    align-items: center;
    background-color: var(--lp-primary);
    border-radius: 0.8rem;
    color: var(--lp-white);
    display: flex;
    flex-direction: row;
    font-size: 4rem;
    font-weight: 700;
    height: 10rem;
    justify-content: center;
    position: relative;
    width: 10rem;
  }

  .lp-user-status {
    border: 0.3rem solid var(--lp-white);
    border-radius: 50%;
    bottom: -0.6rem;
    display: block;
    height: 2rem;
    position: absolute;
    right: -0.6rem;
    width: 2rem;

    &.active {
      background-color: var(--lp-success);
    }

    &.inactive {
      background-color: var(--lp-danger);
    }
  }

  .lp-user-name {
    font-size: 1.8rem;
    font-weight: 700;
  }

  .lp-user-email {
    background-color: var(--lp-primary);
    border-radius: 1.6rem;
    color: var(--lp-white);
    font-size: 1.4rem;
    padding: 0.2rem 0.8rem;
  }

  .lp-user-role {
    background-color: var(--lp-primary-250);
    border-radius: 1.6rem;
    color: var(--lp-primary);
    font-size: 1.4rem;
    padding: 0.2rem 0.8rem;
  }
}
