/* Drag & Drop */
.lp-dashboard-dnd-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 1.6rem;
  height: 100%;
  margin-top: 1.6rem;
  min-height: 67.6rem;

  .lp-dashboard-dnd-item-container {
    position: relative;
    min-width: 0;
    min-height: 33rem;

    .lp-dashboard-dnd-draggable-item {
      position: relative;
      height: 100%;
      width: 100%;
      z-index: 3;
      display: flex;
      flex-direction: column;
      touch-action: none;
      overflow: auto;
  
      &.dragging {
        z-index: 10;
        opacity: 0.7;
      }

      .lp-title {
        flex: 0 0 auto;
      }
    }
  }

  &.dragging {
    .lp-dashboard-dnd-item-container {
      &.dragging {
        .card {
          position: relative;
          
          &::after {
            content: '';
            position: absolute;
            inset: 0;
            background: rgba(var(--lp-primary-rgb), 0.1);
            border: 2px solid rgba(var(--lp-primary-rgb), 0.3);
            border-radius: inherit;
            pointer-events: none;
          }
        }
      }

      &:not(.dragging) {
        .card {
          opacity: 0.6;
          position: relative;
          
          &::after {
            content: '';
            position: absolute;
            inset: 0;
            background: rgba(var(--lp-secondary-rgb), 0.05);
            border-radius: inherit;
            pointer-events: none;
          }
        }
      }
    }
  }

  .card {
    flex: 1 1 auto;
    overflow-y: auto;
    min-height: 0;
  
    &.with-calendar {
      &.dashboard {
        .rbc-time-content {
          max-height: 70rem;
        }
      }
    }
  
    &.with-emails {
      &.dashboard {
        .card-body {
          padding: 0;
          display: contents;
  
          .lp-emails-filter {
            padding: 0.8rem 1.6rem;
          }
  
          .lp-emails-list {
            overflow-y: auto;
          }
  
          .lp-emails-pagination {
            margin: 0;
            padding: 0.8rem 1.6rem;
          }
        }
      }
    }
  
    &.with-list {
      &.dashboard-list {
        display: flex;
        height: calc(100% - 3.7rem);
  
        .inner-content {
          display: table;
          height: auto;
          width: 100%;
      
          .card-header {
            display: table-row;
            position: sticky;
            top: 0;
            width: 100%;
            z-index: 99;
      
            > div {
              display: table-cell;
              padding: 1.4rem 0.8rem;
      
              &:first-child {
                padding-left: 1.6rem;
              }
      
              &:last-child {
                padding-right: 1.6rem;
              }
            }
          }
      
          .card-body {
            display: table-row-group;
            width: 100%;
            height: unset;
      
            .lp-list-row {
              display: table-row;
              width: 100%;
      
              > div {
                border-top: 0.1rem solid var(--lp-secondary-200);
                display: table-cell;
                line-height: 2.8rem;
                padding: 0.8rem;
                vertical-align: middle;
                width: auto;
      
                &:first-child {
                  padding-left: 1.6rem;
                }
        
                &:last-child {
                  padding-right: 1.6rem;
                }
      
                &.lp-list-action {
                  padding-top: 0.4rem;
                  padding-bottom: 0.4rem;
                  width: 5.6rem;
                }
              }
            }
          }
        }
      }
  
      &.empty-list {
        .card-body {
          height: unset;
        }
      }
    }
  }
}

.lp-dashboard-dnd-item-drag-overlay {
  align-items: center;
  background-color: var(--lp-white);
  border: 0.1rem solid var(--lp-border-color-translucent);
  border-radius: 5rem;
  box-shadow: 0 0 0.5rem 0.3rem rgba(var(--lp-text-rgb), 0.1);
  cursor: pointer;
  display: inline-flex;
  font-size: 2.4rem;
  height: 4rem;
  justify-content: center;
  margin-left: -1.5rem;
  margin-top: -1rem;
  padding: 0 0.8rem;
  gap: 0.8rem;

  .lp-dashboard-dnd-item-drag-overlay-icon {
    font-size: 1.6rem;
    margin-bottom: 3px;
  }

  .lp-dashboard-dnd-item-drag-overlay-title {
    font-size: 1.6rem;
    font-weight: 600;
    text-transform: capitalize;
  }
}

.lp-dashboard-hide-items {
  position: fixed;
  right: 0;
  top: 0;

  .btn {
    border-radius: 0 0 0 0.4rem;
    line-height: 1;
    padding: 0.4rem;
  }
}
