.lp-body-content {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  height: 100vh; /* Fallback for browsers which cannot support Custom Properties */
  height: var(--vh, 1vh);
  overflow: hidden;
  width: 100vw;

  &.delegate {
    &:has(.lp-navbar.collapsed) {
      .lp-delegate-message {
        left: 7rem;
      }

      &:has(.lp-quick-search-panel.collapsed) {
        .lp-delegate-message {
          left: 12rem;
        }
      }

      &:has(.lp-quick-search-panel.expanded) {
        .lp-delegate-message {
          left: 29rem;
        }
      }
    }

    &:has(.lp-navbar.expanded) {
      .lp-delegate-message {
        left: 27.5rem;
      }

      &:has(.lp-quick-search-panel.collapsed) {
        .lp-delegate-message {
          left: 32.5rem;
        }
      }

      &:has(.lp-quick-search-panel.expanded) {
        .lp-delegate-message {
          left: 49.5rem;
        }
      }
    }
  }
}

.lp-body-content-left {
  position: relative;
  z-index: 10;
}

.lp-body-content-right {
  background-color: #EFF0F0;
  flex: 1 1 100%;
  position: relative;
}

.lp-page-with-sidebar {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  height: 100vh; /* Fallback for browsers which cannot support Custom Properties */
  height: var(--vh, 1vh);
  overflow: hidden;

  .lp-page-content {
    width: 100%;
  }
}

.lp-page-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 40rem;
  overflow-y: scroll;
  padding: 2.4rem 1.6rem 2.4rem 2.4rem;

  &:has(.react-tabs) {
    overflow-y: auto;
    padding-right: 2.4rem;
  }
}

.lp-delegate-message {
  background-color: var(--lp-primary-alt-250);
  color: var(--lp-primary-alt);
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1;
  padding: 0.4rem 0.8rem;
  position: fixed;
  top: 0;
  width: 100%;
}
