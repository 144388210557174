/* Payments */
.lp-payments-form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.lp-sticky-bottom {
  background-color: var(--lp-white);
  bottom: -1.6rem;
  margin-top: auto;
  position: sticky;
}
