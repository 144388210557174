/* ====================
  LawPage AgGrid Custom Theme
==================== */
@use "node_modules/ag-grid-community/styles" as ag;

@include ag.grid-styles((
  themes: (
    lawpage: (extend-theme: alpine),
    lawpage-dark: (extend-theme: alpine-dark),
  ),
));

/* AgGrid theme custom variables */
.ag-theme-lawpage,
.ag-theme-lawpage-dark {
  --ag-foreground-color: var(--lp-text);  // done
  --ag-data-color: var(--ag-foreground-color);
  --ag-secondary-foreground-color: var(--ag-foreground-color);
  --ag-header-foreground-color: var(--lp-text);  // done
  --ag-disabled-foreground-color: rgba(0, 0, 0, 0.5);
  --ag-background-color: var(--lp-white);  // done
  --ag-header-background-color: var(--lp-light);  // done
  --ag-subheader-background-color: transparent;
  --ag-subheader-toolbar-background-color: transparent;
  --ag-control-panel-background-color: var(--ag-background-color);  // done
  --ag-side-button-selected-background-color: var(--ag-control-panel-background-color);
  --ag-selected-row-background-color: var(--lp-primary-250);  // done
  --ag-odd-row-background-color: var(--ag-background-color);
  --ag-modal-overlay-background-color: rgba(255, 255, 255, 0.66);
  --ag-row-hover-color: rgba(210, 207, 219, 0.1);  // done
  --ag-column-hover-color: transparent;
  --ag-range-selection-border-color: transparent;  // done
  --ag-range-selection-border-style: solid;  // done
  --ag-range-selection-background-color: rgba(0, 0, 0, 0.2);
  --ag-range-selection-background-color-2: var(--ag-range-selection-background-color);
  --ag-range-selection-background-color-3: var(--ag-range-selection-background-color);
  --ag-range-selection-background-color-4: var(--ag-range-selection-background-color);
  --ag-range-selection-highlight-color: var(--ag-range-selection-border-color);
  --ag-selected-tab-underline-color: var(--ag-range-selection-border-color);
  --ag-selected-tab-underline-width: 0;
  --ag-selected-tab-underline-transition-speed: 0s;
  --ag-range-selection-chart-category-background-color: rgba(0, 255, 132, 0.1);
  --ag-range-selection-chart-background-color: rgba(0, 88, 255, 0.1);
  --ag-header-cell-hover-background-color: transparent;
  --ag-header-cell-moving-background-color: var(--lp-primary-250);  // done
  --ag-value-change-value-highlight-background-color: rgba(22, 160, 133, 0.5);
  --ag-value-change-delta-up-color: #43a047;
  --ag-value-change-delta-down-color: #e53935;
  --ag-chip-background-color: transparent;
  --ag-borders: solid 0.1rem;  // done
  --ag-border-color: var(--lp-gray-200);  // done
  --ag-borders-critical: var(--ag-borders);
  --ag-borders-secondary: var(--ag-borders);
  --ag-secondary-border-color: var(--ag-border-color);
  --ag-borders-row: var(--ag-borders-secondary);
  --ag-row-border-color: var(--ag-secondary-border-color);
  --ag-cell-horizontal-border: solid transparent;
  --ag-borders-input: var(--ag-borders-secondary);
  --ag-input-border-color: var(--lp-secondary-400);  // done
  --ag-borders-input-invalid: solid 1px;
  --ag-input-border-color-invalid: var(--ag-invalid-color);
  --ag-borders-side-button: var(--ag-borders);
  --ag-border-radius: 0;  // done
  --ag-header-column-separator-display: block;  // done
  --ag-header-column-separator-height: 100%;  // done
  --ag-header-column-separator-width: 0.1rem;  // done
  --ag-header-column-separator-color: var(--ag-secondary-border-color);
  --ag-header-column-resize-handle-display: none;
  --ag-header-column-resize-handle-height: 50%;
  --ag-header-column-resize-handle-width: 1px;
  --ag-header-column-resize-handle-color: var(--ag-secondary-border-color);
  --ag-invalid-color: var(--lp-danger);  // done
  --ag-input-disabled-border-color: var(--ag-input-border-color);
  --ag-input-disabled-background-color: transparent;
  --ag-checkbox-background-color: transparent;
  --ag-checkbox-border-radius: var(--ag-border-radius);
  --ag-checkbox-checked-color: var(--lp-primary);  // done
  --ag-checkbox-unchecked-color: var(--lp-secondary);  // done
  --ag-checkbox-indeterminate-color: var(--ag-checkbox-unchecked-color);
  --ag-toggle-button-off-border-color: var(--ag-checkbox-unchecked-color);
  --ag-toggle-button-off-background-color: var(--ag-checkbox-unchecked-color);
  --ag-toggle-button-on-border-color: var(--ag-checkbox-checked-color);
  --ag-toggle-button-on-background-color: var(--ag-checkbox-checked-color);
  --ag-toggle-button-switch-background-color: var(--ag-background-color);
  --ag-toggle-button-switch-border-color: var(--ag-toggle-button-off-border-color);
  --ag-toggle-button-border-width: 1px;
  --ag-toggle-button-height: var(--ag-icon-size);
  --ag-toggle-button-width: calc(var(--ag-toggle-button-height) * 2);
  --ag-input-focus-box-shadow: none;
  --ag-input-focus-border-color: none;
  --ag-minichart-selected-chart-color: var(--ag-checkbox-checked-color);
  --ag-minichart-selected-page-color: var(--ag-checkbox-checked-color);
  --ag-grid-size: 0.8rem;  // done
  --ag-icon-size: 1.6rem;  // done
  --ag-widget-container-horizontal-padding: calc(var(--ag-grid-size) * 1.5);
  --ag-widget-container-vertical-padding: calc(var(--ag-grid-size) * 1.5);
  --ag-widget-horizontal-spacing: calc(var(--ag-grid-size) * 2);
  --ag-widget-vertical-spacing: var(--ag-grid-size);
  --ag-cell-horizontal-padding: calc(var(--ag-grid-size) * 2);  // done
  --ag-cell-widget-spacing: var(--ag-cell-horizontal-padding);
  --ag-row-height: calc(var(--ag-grid-size) * 6);  // done
  --ag-header-height: calc(var(--ag-row-height) - 0.1rem);  // done
  --ag-list-item-height: calc(var(--ag-grid-size) * 5);
  --ag-column-select-indent-size: calc(var(--ag-grid-size) + var(--ag-icon-size));
  --ag-row-group-indent-size: calc(var(--ag-cell-widget-spacing) + var(--ag-icon-size));
  --ag-filter-tool-panel-group-indent: 16px;
  --ag-tab-min-width: 220px;
  --ag-menu-min-width: 181px;
  --ag-side-bar-panel-width: 200px;
  --ag-font-family: var(--lp-font-sans-serif);  // done
  --ag-font-size: 1.4rem;  // done
  --ag-card-radius: var(--ag-border-radius);
  --ag-card-shadow: none;
  --ag-popup-shadow: 0 0 0.5rem 0.3rem rgba(var(--lp-text-rgb), 0.1);
  --ag-wrapper-radius: 0.4rem;
  --ag-wrapper-shadow: 0 0 0.5rem 0.3rem rgba(var(--lp-text-rgb), 0.1);
}

/* AgGrid theme custom style */
.grid {
  border-radius: var(--ag-wrapper-radius);
  box-shadow: var(--ag-wrapper-shadow);
  height: 100%;
  min-height: 45rem;

  .card & {
    border-radius: 0;
    box-shadow: none;
  }

  .ag-root-wrapper {
    border: 0.1rem solid rgba(var(--lp-black-rgb), 0.175);
    border-radius: var(--ag-wrapper-radius);
    height: 100%;
    min-height: 45rem;

    .ag-root-wrapper-body {
      height: 100%;
    }

    .card & {
      border-left: 0 none;
      border-right: 0 none;
      border-bottom: 0 none;
      border-radius: 0 0 0.4rem 0.4rem;
    }

    // Fix pagination size on small screens
    .ag-paging-panel {
      display: flex;
      flex-wrap: wrap;
      height: max-content;

      .ag-paging-row-summary-panel,
      .ag-paging-page-summary-panel {
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }

    .ag-column-select {
      // Remove checkbox for select/deselect all in column chooser
      .ag-column-select-header .ag-column-select-header-checkbox {
        display: none;
      }

      .ag-column-select-list.deselection-disabled .ag-column-select-column .ag-column-select-checkbox .ag-checkbox-input-wrapper.ag-checked {
        opacity: 0.35;

        .ag-input-field-input {
          pointer-events: none;
        }
      }
    }
  }

  .ag-header {
    font-size: 1.2rem;
    text-transform: uppercase;

    .ag-header-row {
      .ag-floating-filter-button {
        display: none !important;
      }
    }
  }

  .ag-header-row-column-filter {
    .ag-floating-filter {
      border-top: 0 none;
      font-size: 1.4rem;

      .ag-floating-filter-input,
      .ag-input-field,
      .ag-input-wrapper,
      .ag-input-field-input {
        height: 4rem;
      }

      .ag-input-field-input {
        border-radius: 0.4rem;
      }
    }
  }

  .ag-floating-filter {
    .lp-select {
      text-transform: none;

      .lp-select__control {
        .lp-select__value-container {
          .lp-select__placeholder,
          .lp-select__single-value {
            font-size: 1.4rem;
            font-weight: 400;
          }
    
          &.lp-select__value-container--is-multi {
            flex-wrap: nowrap;

            .lp-select__multi-value {
              .lp-select__multi-value__label {
                font-size: 1.4rem;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }

  .ag-body-viewport {
    overflow: overlay;

    @-moz-document url-prefix() {
      overflow: auto;
    }

    .ag-pinned-left-cols-container {
      .ag-cell:last-child {
        &.ag-cell-focus:not(.ag-cell-range-selected):focus-within {
          border-right-color: var(--lp-secondary-200);
        }
      }
    }

    .ag-pinned-right-cols-container {
      .ag-cell {
        &.ag-cell-focus:not(.ag-cell-range-selected):focus-within {
          border-left-color: var(--lp-secondary-200);
        }
      }
    }

    .ag-row-group {
      gap: 2px;
    }

    .ag-row-group-expanded {
      background-color: rgba(var(--lp-primary-alt-250-rgb), 0.25);

      &.ag-row-selected {
        .ag-group-expanded,
        .ag-group-contracted {
          .btn-icon {
            background-color: var(--lp-white);
          }
        }
      }
    }

    .ag-row-group-contracted {
      &.ag-row-selected {
        .ag-group-expanded,
        .ag-group-contracted {
          .btn-icon {
            background-color: var(--lp-white);
          }
        }
      }
    }
  }

  .ag-header-cell-sorted-asc,
  .ag-header-cell-sorted-desc {
    color: var(--lp-success);
  }

  .ag-cell-value {
    > svg {
      font-size: 2rem;
    }
  }

  .ag-cell-expandable {
    .ag-group-expanded,
    .ag-group-contracted {
      margin-right: 0;

      .btn-icon {
        padding: 0.4rem;
      }
    }
  }

  //.ag-row-group-leaf-indent,
  //.ag-cell-expandable {
  //  .ag-group-value {
  //    display: none;
  //  }
  //}

  .ag-full-width-container {
    .ag-details-row {
      background-color: rgba(var(--lp-primary-alt-250-rgb), 0.25);
      min-height: 7.8rem;
      padding: 0.8rem;
    }

    .lp-details-grid-title {
      color: var(--lp-primary-alt);
      font-size: 1.2rem;
      font-weight: 700;
      letter-spacing: 0.1rem;
      text-transform: uppercase;
    }
  }

  .ag-details-grid {
    border: 0.1rem solid var(--lp-secondary-400);
    border-radius: 0.4rem;

    .ag-root-wrapper {
      border: 0 none;
      border-radius: 0.4rem;
      min-height: 0;

      .ag-root-wrapper-body {
        min-height: 0;
      }

      .ag-header {
        height: 3rem !important;
        min-height: 3rem !important;
        text-transform: none;

        .ag-header-row {
          height: 2.9rem !important;

          .ag-header-cell {
            height: 2.9rem !important;
          }
        }
      }

      .ag-body {
        min-height: 4rem;
      }

      .ag-overlay {
        height: calc(100% - 3rem);
        top: 3rem;

        .ag-overlay-no-rows-center {
          color: var(--lp-secondary);
          font-size: 1.4rem;
        }
      }
    }
  }

  .lp-type-icon-cell {
    .ag-cell-value {
      align-items: center;
      display: flex;
      flex-direction: row;

      > svg {
        font-size: 2.4rem;
        height: 2.4rem;
        margin-right: 0.8rem;
        min-width: 2.4rem;
        width: 2.4rem;

        + span {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .lp-cell-time-slot {
    align-items: center;
    display: flex;

    &:before {
      border-radius: 2rem;
      content: '';
      display: block;
      height: 1.6rem;
      margin-right: 0.8rem;
      min-width: 1.6rem;
      width: 1.6rem;
    }

    &.unknown {
      &:before {
        background: var(--lp-secondary);
      }
    }
  
    &.free {
      &:before {
        background: var(--lp-success);
      }
    }
  
    &.tentative {
      &:before {
        background: var(--lp-info);
      }
    }
  
    &.busy {
      &:before {
        background: var(--lp-primary);
      }
    }
  
    &.oof {
      &:before {
        background: var(--lp-danger);
      }
    }
  
    &.workingelsewhere {
      &:before {
        background: var(--lp-primary-alt);
      }
    }
  }

  .lp-activity-type-cell {
    height: 100%;

    @mixin activity-type-cell-style($background, $color) {
      span {
        background: $background;
        color: $color;
      }
    }

    span {
      border-radius: 1rem;
      flex: 0 0 auto;
      font-size: 1.2rem;
      line-height: 1;
      padding: 0.4rem 0.8rem;
    }

    &.recordable-event {
      @include activity-type-cell-style(var(--lp-recordable-event), var(--lp-background));
    }

    &.user-note {
      @include activity-type-cell-style(var(--lp-user-note), var(--lp-text));
    }

    &.system-note {
      @include activity-type-cell-style(var(--lp-system-note), var(--lp-background));
    }

    &.microsoft-word {
      @include activity-type-cell-style(var(--lp-microsoft-word), var(--lp-background));
    }

    &.pdf {
      @include activity-type-cell-style(var(--lp-pdf), var(--lp-background));
    }

    &.microsoft-excel {
      @include activity-type-cell-style(var(--lp-microsoft-excel), var(--lp-background));
    }

    &.csv {
      @include activity-type-cell-style(var(--lp-csv), var(--lp-background));
    }

    &.microsoft-powerpoint {
      @include activity-type-cell-style(var(--lp-microsoft-powerpoint), var(--lp-background));
    }

    &.text-file {
      @include activity-type-cell-style(var(--lp-text-file), var(--lp-background));
    }

    &.image {
      @include activity-type-cell-style(var(--lp-image), var(--lp-text));
    }

    &.audio-video {
      @include activity-type-cell-style(var(--lp-audio-video), var(--lp-background));
    }

    &.archive {
      @include activity-type-cell-style(var(--lp-archive), var(--lp-background));
    }

    &.email-file {
      @include activity-type-cell-style(var(--lp-email-file), var(--lp-background));
    }

    &.html-file {
      @include activity-type-cell-style(var(--lp-html-file), var(--lp-background));
    }

    &.microsoft-onenote {
      @include activity-type-cell-style(var(--lp-microsoft-onenote), var(--lp-background));
    }

    &.other-file {
      @include activity-type-cell-style(var(--lp-other-file), var(--lp-text));
    }

    &.received-mail {
      @include activity-type-cell-style(var(--lp-received-mail), var(--lp-background));
    }

    &.sent-mail {
      @include activity-type-cell-style(var(--lp-sent-mail), var(--lp-background));
    }

    &.sms {
      @include activity-type-cell-style(var(--lp-sms), var(--lp-background));
    }

    &.whatsapp {
      @include activity-type-cell-style(var(--lp-whatsapp), var(--lp-text));
    }

    &.appointment {
      @include activity-type-cell-style(var(--lp-appointment), var(--lp-background));
    }

    &.disbursement {
      @include activity-type-cell-style(var(--lp-disbursement), var(--lp-background));
    }

    &.incidental-expense {
      @include activity-type-cell-style(var(--lp-incidental-expense), var(--lp-background));
    }

    &.invoice {
      @include activity-type-cell-style(var(--lp-invoice), var(--lp-background));
    }

    &.other {
      @include activity-type-cell-style(var(--lp-other), var(--lp-text));
    }
  }

  .lp-actions-cell {
    overflow: visible;

    .ag-cell-wrapper {
      height: 100%;
    }
  }

  .ag-overlay {
    position: relative;

    .ag-overlay-wrapper {
      padding-top: 0 !important;
    }
  }

  .lp-grid-empty {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 0.5;

    .image {
      background-image: url(../../assets/images/empty.webp);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      height: 30rem;
      width: 40rem;
    }

    .text {
      color: var(--lp-secondary);
      font-size: 2rem;
    }
  }

  .ag-layout-auto-height,
  .ag-layout-print {
    .ag-center-cols-clipper,
    .ag-center-cols-container {
      min-height: 100%;
    }
  }
}

.grid-no-select {
  .ag-cell {
    user-select: none;
  }
}
