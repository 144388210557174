/* Calendar */
$out-of-range-color:      var(--lp-secondary);
$out-of-range-bg-color:   rgba(var(--lp-secondary-rgb), 0.1);
$calendar-border:         var(--lp-secondary-200);
$cell-border:             var(--lp-secondary-200);
$time-selection-color:    var(--lp-white);
$time-selection-bg-color: rgba(var(--lp-primary-rgb), 0.5);
$date-selection-bg-color: rgba(var(--lp-primary-rgb), 0.25);
$event-bg:                rgba(var(--lp-primary-rgb), 0.25);
$event-border-radius:     0.4rem;
$event-padding:           0.4rem;
$event-zindex:            9;
$event-height:            5rem;
$current-time-color:      var(--lp-primary);
$today-highlight-bg:      rgba(var(--lp-primary-250-rgb), 0.2);

.rbc-calendar {
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
}

.rbc-abs-full {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}

.rbc-ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rbc-rtl {
  direction: rtl;
}

.rbc-off-range {
  color: $out-of-range-color;
}

.rbc-off-range-bg {
  background: $out-of-range-bg-color;
}

.rbc-today {
  background-color: $today-highlight-bg;
}

.rbc-btn {
  -webkit-appearance: button;
  color: inherit;
  cursor: pointer;
  font: inherit;
  margin: 0;
  overflow: visible;
  text-transform: none;

  &[disabled],
  &:disabled,
  &.disabled {
    cursor: not-allowed;
  }
}

button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.rbc-header {
  border-top: 0.3rem solid transparent;
  flex: 1 0 0%;
  font-weight: bold;
  min-height: 0;
  overflow: hidden;
  padding: 0.4rem;
  text-align: center;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;

  & + & {
    border-left: 0.1rem solid $cell-border;
  }

  .rbc-rtl & + & {
    border-left-width: 0;
    border-right: 0.1rem solid $cell-border;
  }

  > a {
    &,
    &:active,
    &:visited {
      color: inherit;
      text-decoration: none;
    }
  }

  &.rbc-today {
    border-top-color: var(--lp-primary);

    span {
      color: var(--lp-primary);
      font-weight: bold;
    }
  }
}

.rbc-button-link {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  margin: 0;
  padding: 0;
  user-select: text;
}

.rbc-row-content {
  -webkit-user-select: none;
  position: relative;
  user-select: none;
  z-index: 4;
}

.rbc-row-content-scrollable {
  display: flex;
  flex-direction: column;
  height: 100%;

  .rbc-row-content-scroll-container {
    -ms-overflow-style: none;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.rbc-toolbar {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.4rem;
  justify-content: center;
  margin-bottom: 1.6rem;

  .rbc-toolbar-label {
    flex-grow: 1;
    font-size: 1.6rem;
    padding: 0 1.6rem;
    text-align: center;
  }

  button {
    background: var(--lp-secondary-200);
    background-image: none;
    border: 0 none;
    border-radius: 0.4rem;
    color: var(--lp-text);
    display: inline-block;
    line-height: normal;
    margin: 0;
    padding: 0.8rem 1.6rem;
    text-align: center;
    transition: 0.25s linear;
    vertical-align: middle;
    white-space: nowrap;

    + button {
      border-left: 0.1rem solid var(--lp-white);
    }

    &:active,
    &.rbc-active {
      background-color: var(--lp-primary);
      color: var(--lp-white);

      &:hover {
        background-color: rgba(var(--lp-primary-rgb), 0.75);
      }
    }

    &:hover {
      background: rgba(var(--lp-secondary-200-rgb), 0.75);
    }
  }
}

.rbc-btn-group {
  display: inline-block;
  white-space: nowrap;

  > button:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  > button:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .rbc-rtl & > button:first-child:not(:last-child) {
    border-radius: 0.4rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .rbc-rtl & > button:last-child:not(:first-child) {
    border-radius: 0.4rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  > button:not(:first-child):not(:last-child) {
    border-radius: 0;
  }

  button + button {
    margin-left: -0.1rem;
  }

  .rbc-rtl & button + button {
    margin-left: 0;
    margin-right: -0.1rem;
  }

  & + &,
  & + button {
    margin-left: 1rem;
  }
}

.rbc-event {
  border-radius: $event-border-radius;
  border-style: solid;
  border-width: 0 0 0 0.5rem;
  box-shadow: none;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 1.2rem;
  margin: 0;
  padding: $event-padding;
  position: relative;
  text-align: left;
  width: 100%;

  .rbc-slot-selecting & {
    cursor: inherit;
    pointer-events: none;
  }

  &.rbc-selected {
    background-color: $event-bg;
  }

  &.past-event {
    opacity: 0.5;
  }

  &.unknown {
    background: rgba(var(--lp-secondary-rgb), 0.25);
    border-color: var(--lp-secondary);
    color: var(--lp-secondary);
  }

  &.free {
    background: rgba(var(--lp-success-rgb), 0.25);
    border-color: var(--lp-success);
    color: var(--lp-success);
  }

  &.tentative {
    background: rgba(var(--lp-info-rgb), 0.25);
    border-color: var(--lp-info);
    color: var(--lp-info);
  }

  &.busy {
    background: rgba(var(--lp-primary-rgb), 0.25);
    border-color: var(--lp-primary);
    color: var(--lp-primary);
  }

  &.out-of-office {
    background: rgba(var(--lp-danger-rgb), 0.25);
    border-color: var(--lp-danger);
    color: var(--lp-danger);
  }

  &.working-elsewhere {
    background: rgba(var(--lp-primary-alt-rgb), 0.25);
    border-color: var(--lp-primary-alt);
    color: var(--lp-primary-alt);
  }

  .rbc-event-content {
    font-weight: bold;
    overflow: hidden;

    svg {
      font-size: 1.4rem;
      margin-left: 0.4rem;
      vertical-align: text-bottom;
    }
  }
}

.rbc-event-label {
  @extend .rbc-ellipsis;

  &:empty {
    display: none;
  }
}

.rbc-event-overlaps {
  box-shadow: -0.1rem 0.1rem 0.5rem 0 rgba(51, 51, 51, 0.5);
}

.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-row {
  display: flex;
  flex-direction: row;
}

.rbc-row-segment {
  padding: 0 0.1rem 0.1rem 0.1rem;
}

.rbc-selected-cell {
  background-color: $date-selection-bg-color;
}

.rbc-show-more {
  @extend .rbc-ellipsis;
  background-color: rgba(255, 255, 255, 0.3);
  color: var(--lp-primary);
  font-size: 1.2rem;
  font-weight: bold;
  height: auto;
  line-height: normal;
  z-index: $event-zindex;
}

.rbc-calendar {
  .rbc-month-view {
    -webkit-user-select: none;
    border: 0.1rem solid $calendar-border;
    border-radius: $event-border-radius;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    height: 100%;
    min-height: 40rem;
    position: relative;
    user-select: none;
    width: 100%;

    .rbc-today {
      background-color: rgba(var(--lp-primary-250-rgb), 0.5);
    }

    .rbc-now,
    .rbc-current {
      .rbc-button-link {
        color: var(--lp-primary);
        font-weight: bold;
      }
    }
  }
}

.rbc-month-header {
  background-color: rgba(var(--lp-secondary-rgb), 0.05);
  border-bottom: 0.1rem solid $calendar-border;
  display: flex;
  flex-direction: row;
}

.rbc-month-row {
  display: flex;
  flex: 1 0 0;
  flex-basis: 0;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
  
  & + & {
    border-top: 0.1rem solid $cell-border;
  }
}

.rbc-date-cell {
  flex: 1 1 0;
  min-width: 0;
  padding-right: 0.5rem;
  text-align: right;

  &.rbc-now {
    font-weight: bold;
  }

  > a {
    &,
    &:active,
    &:visited {
      color: inherit;
      text-decoration: none;
    }
  }
}

.rbc-row-bg {
  @extend .rbc-abs-full;
  display: flex;
  flex: 1 0 0;
  flex-direction: row;
  overflow: hidden;
}

.rbc-day-bg {
  flex: 1 0 0%;

  & + & {
    border-left: 0.1rem solid $cell-border;
  }

  .rbc-rtl & + & {
    border-left-width: 0;
    border-right: 0.1rem solid $cell-border;
  }
}

.rbc-overlay {
  background-color: var(--lp-white);
  border: 0.1rem solid var(--lp-secondary-200);
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.25);
  max-height: 20rem;
  overflow-y: auto;
  padding: 1rem;
  position: absolute;
  z-index: $event-zindex + 1;
  
  > * + * {
    margin-top: 0.1rem;
  }
}

.rbc-overlay-header {
  background: var(--lp-white);
  border-bottom: 0.1rem solid var(--lp-secondary-200);
  color: var(--lp-secondary);
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  margin: -1rem -1rem 1rem -1rem;
  padding: 0.2rem 1rem;
  position: sticky;
  text-transform: uppercase;
  top: -1rem;
  z-index: 10;
}

.rbc-agenda-view {
  border-radius: $event-border-radius;
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  overflow: auto;

  table.rbc-agenda-table {
    border: 0.1rem solid $cell-border;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    thead {
      background-color: rgba(var(--lp-secondary-rgb), 0.05);

      > tr {
        > th {
          border-bottom: 0.1rem solid $cell-border;
          padding: 0.3rem 0.5rem;
          text-align: left;
    
          .rbc-rtl & {
            text-align: right;
          }
        }
      }
    }

    tbody {
      > tr {
        + tr {
          border-top: 0.1rem solid $cell-border;
        }

        > td {
          border-left: 0.1rem solid $cell-border;
          padding: 0.5rem 1rem;
          vertical-align: top;
        }
      }
    }

    .rbc-agenda-time-cell {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      text-transform: lowercase;
    }

    .rbc-rtl & {
      tbody > tr > td {
        border-left-width: 0;
        border-right: 0.1rem solid $cell-border;
      }
    }

    .rbc-header {
      border-top: initial;
    }
  }
}

.rbc-agenda-time-cell {
  text-transform: lowercase;

  .rbc-continues-after:after {
    content: ' »'
  }
  .rbc-continues-prior:before {
    content: '« '
  }
}

.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
  white-space: nowrap;
}

.rbc-agenda-date-cell {
  text-align: right;
}

.rbc-agenda-event-cell {
  width: 100%
}

.rbc-time-column {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  .rbc-timeslot-group {
    flex: 1;
  }
}

.rbc-timeslot-group {
  border-bottom: 0.1rem solid $cell-border;
  display: flex;
  flex-flow: column nowrap;
  min-height: $event-height;

  .rbc-time-slot {
    line-height: 1;
  }
}

.rbc-time-gutter,
.rbc-header-gutter {
  flex: none;
}

.rbc-label {
  color: rgba(var(--lp-text-rgb), 0.75);
  font-size: 1.2rem;
  line-height: 1;
  padding: 0 0.5rem;
}

.rbc-day-slot {
  position: relative;

  .rbc-events-container {
    bottom: 0;
    left: 0;
    margin-right: 1rem;
    position: absolute;
    right: 0;
    top: 0;

    &.rbc-rtl {
      left: 1rem;
      right: 0;
    }
  }

  .rbc-event {
    align-items: flex-start;
    display: flex;
    flex-flow: column wrap;
    max-height: 100%;
    min-height: 2rem;
    overflow: hidden;
    position: absolute;
  }
  
  .rbc-background-event {
    @extend .rbc-event;
    opacity: 0.75;
  }

  .rbc-event-label {
    flex: none;
    padding-right: 0.5rem;
    width: auto;
  }

  .rbc-event-content {
    flex: 1 1 0;
    height: 100%;
    min-height: 1em;
    width: 100%;
  }

  .rbc-time-slot {
    + .rbc-time-slot {
      border-top: 0.1rem dashed $cell-border;
    }
  }
}

.rbc-time-view-resources {
  .rbc-time-gutter,
  .rbc-time-header-gutter {
    background-color: var(--lp-white);
    border-right: 0.1rem solid $cell-border;
    margin-right: -0.1rem;
    left: 0;
    position: sticky;
    z-index: 10;
  }

  .rbc-time-header {
    overflow: hidden;
  }

  .rbc-time-header-content {
    flex: 1 0 0;
    flex-basis: 0;
    min-width: auto;
  }

  .rbc-time-header-cell-single-day {
    display: none;
  }

  .rbc-day-slot {
    min-width: 14rem;
  }

  .rbc-header,
  .rbc-day-bg {
    flex:  1 1 0;
    flex-basis: 0;
    width: 14rem;
  }
}

.rbc-time-slot {
  flex: 1 0 0;

  &.rbc-now {
    font-weight: bold;
  }
}

.rbc-day-header {
  text-align: center;
}

.rbc-slot-selection {
  background-color: $time-selection-bg-color;
  color: $time-selection-color;
  font-size: 75%;
  padding: 0.3rem;
  position: absolute;
  width: 100%;
  z-index: 10;
}

.rbc-slot-selecting {
  cursor: move;
}

.rbc-time-view {
  border: 0.1rem solid $calendar-border;
  border-radius: $event-border-radius;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 40rem;
  width: 100%;

  .rbc-time-gutter {
    text-align: right;
    white-space: nowrap;
  }

  .rbc-allday-cell {
    box-sizing: content-box;
    padding: 0.4rem 0;
    position: relative;
    width: 100%;

    .rbc-row {
      min-height: 0;
    }
  }

  .rbc-allday-cell + .rbc-allday-cell {
    border-left: 0.1rem solid $cell-border;
  }

  .rbc-allday-events {
    position: relative;
    z-index: 4;
  }

  .rbc-row {
    box-sizing: border-box;
    min-height: 2rem;
  }
}

.rbc-time-header {
  background-color: rgba(var(--lp-secondary-rgb), 0.05);
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;

  &.rbc-overflowing {
    border-right: 0.1rem solid $cell-border;
  }

  .rbc-rtl &.rbc-overflowing {
    border-right-width: 0;
    border-left: 0.1rem solid $cell-border;
  }

  > .rbc-row:first-child {
    border-bottom: 0.1rem solid $cell-border;
  }

  > .rbc-row.rbc-row-resource {
    border-bottom: 0.1rem solid $cell-border;
  }
}

.rbc-time-header-cell-single-day {
  display: none;
}

.rbc-time-header-content {
  border-left: 0.1rem solid $cell-border;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0;

  & + & {
    margin-left: -0.1rem;
  }

  .rbc-rtl & {
    border-left-width: 0;
    border-right: 0.1rem solid $cell-border;
  }

  > .rbc-row.rbc-row-resource {
    border-bottom: 0.1rem solid $cell-border;
    flex-shrink: 0;
  }
}

.rbc-time-content {
  align-items: flex-start;
  border-top: 0.3rem solid $calendar-border;
  display: flex;
  flex: 1 0 0%;
  overflow-y: auto;
  position: relative;
  width: 100%;

  > .rbc-time-gutter {
    flex: none;
  }

  > * + * > * {
    border-left: 0.1rem solid $cell-border;
  }

  .rbc-rtl & > * + * > * {
    border-left-width: 0;
    border-right: 0.1rem solid $cell-border;
  }

  > .rbc-day-slot {
    -webkit-user-select: none;
    user-select: none;
    width: 100%;
  }
}

.rbc-current-time-indicator {
  background-color: $current-time-color;
  height: 0.2rem;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  z-index: 3;

  &:before {
    background-color: $current-time-color;
    border-radius: 1rem;
    content: '';
    display: block;
    height: 1.1rem;
    left: -0.7rem;
    position: absolute;
    top: -0.5rem;
    width: 1.1rem;
  }
}

.rbc-addons-dnd {
  .rbc-addons-dnd-row-body {
    position: relative;
  }
  .rbc-addons-dnd-drag-row {
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .rbc-addons-dnd-over {
    background-color: $date-selection-bg-color;
  }

  .rbc-event {
    transition: opacity 150ms;

    &:hover {
      .rbc-addons-dnd-resize-ns-icon,
      .rbc-addons-dnd-resize-ew-icon {
        display: block;
      }
    }
  }

  .rbc-addons-dnd-dragged-event {
    opacity: 0;
  }

  &.rbc-addons-dnd-is-dragging .rbc-event:not(.rbc-addons-dnd-dragged-event):not(.rbc-addons-dnd-drag-preview) {
    opacity: 0.5;
  }

  .rbc-addons-dnd-resizable {
    height: 100%;
    position: relative;
    width: 100%;
  }

  .rbc-addons-dnd-resize-ns-anchor {
    position: absolute;
    text-align: center;
    width: 100%;

    &:first-child {
      top: 0;
    }
    
    &:last-child {
      bottom: 0;
    }

    .rbc-addons-dnd-resize-ns-icon {
      border-top: 0.3rem double;
      cursor: ns-resize;
      display: none;
      margin: 0 auto;
      width: 1rem;
    }
  }

  .rbc-addons-dnd-resize-ew-anchor {
    bottom: 0;
    position: absolute;
    top: 0.4rem;

    &:first-child {
      left: 0;
    }
    
    &:last-child {
      right: 0;
    }

    .rbc-addons-dnd-resize-ew-icon {
      border-left: 0.3rem double;
      cursor: ew-resize;
      display: none;
      height: 1rem;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}

.rbc-week-view {
  .rbc-header {
    .rbc-button-link {
      display: flex;
      flex-direction: column-reverse;

      strong {
        color: var(--lp-text);
        font-size: 2.4rem;
        font-weight: normal;
        line-height: 1;
      }

      span {
        color: var(--lp-secondary);
        font-size: 1.2rem;
        font-weight: normal;
        line-height: 1;
        text-transform: uppercase;
      }
    }
  }
}

.lp-event-description {
  .lp-value {
    word-break: break-word;
  }
}

.lp-calendar-toolbar {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.6rem;
  width: 100%;
}

.lp-calendar-btns {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
}

.lp-calendar-nav {
  align-items: stretch;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: row;

  div {
    align-items: center;
    background-color: var(--lp-secondary-200);
    color: var(--lp-text);
    cursor: pointer;
    display: flex;
    font-size: 1.4rem;
    justify-content: center;
    line-height: 1;
    padding: 0.8rem;
    text-align: center;

    &:first-child {
      border-radius: 0.4rem 0 0 0.4rem;

      &:last-child {
        border-radius: 0.4rem;
      }
    }

    &:last-child {
      border-radius: 0 0.4rem 0.4rem 0;
    }

    &:hover {
      background: rgba(var(--lp-secondary-200-rgb), 0.75);
    }

    svg {
      font-size: 1.8rem;
    }

    + div {
      border-left: 0.1rem solid var(--lp-background);
    }
  }
}

.lp-calendar-legend {
  .btn {
    font-size: 1.4rem;
    line-height: 1;
    padding: 1rem;
  }

  .dropdown-menu {
    padding: 0.4rem 0;
  }

  .dropdown-item {
    align-items: center;
    display: flex;
    flex-direction: row;
    pointer-events: none;
    padding: 0.4rem 1.6rem;

    &:before {
      border-radius: 2rem;
      content: '';
      display: block;
      height: 1.6rem;
      margin-right: 0.8rem;
      width: 1.6rem;
    }

    &.unknown {
      &:before {
        background: var(--lp-secondary);
      }
    }
  
    &.free {
      &:before {
        background: var(--lp-success);
      }
    }
  
    &.tentative {
      &:before {
        background: var(--lp-info);
      }
    }
  
    &.busy {
      &:before {
        background: var(--lp-primary);
      }
    }
  
    &.out-of-office {
      &:before {
        background: var(--lp-danger);
      }
    }
  
    &.working-elsewhere {
      &:before {
        background: var(--lp-primary-alt);
      }
    }

    &.other-ltes {
      svg {
        color: var(--lp-text);
        margin-left: 0.1rem;
        margin-right: 0.9rem;
      }
      &:before {
        display: none;
      }
    }
  }
}

.lp-calendar-date {
  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      input {
        background-color: transparent;
        border: 0 none;
        border-radius: 0;
        cursor: pointer;
        display: flex;
        font-size: 1.6rem;
        line-height: var(--lp-body-line-height);
        padding: 0;
        text-align: center;
        transition: none;
        width: auto;
      }
    }
  }

  .react-datepicker-popper {
    z-index: 30;
  }

  &:not(.appointments) {
    .react-datepicker-wrapper {
      .react-datepicker__input-container {
        input {
          min-width: 23rem;
        }
      }
    }
  }
}

.lp-calendar-view {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  gap: 0.8rem;

  div {
    border-radius: 0.8rem;
    color: var(--lp-secondary);
    cursor: pointer;
    font-size: 1.4rem;
    line-height: 1;
    padding: 0.8rem 1.6rem;

    &:hover {
      background: rgba(var(--lp-secondary-200-rgb), 0.5);
    }

    &.selected {
      background: var(--lp-primary);
      color: var(--lp-background);
    }
  }
}

.lp-calendar-event-tooltip {
  background: var(--lp-secondary-200);
  border: 0.1rem solid var(--lp-secondary-400);
  border-radius: 0.4rem;
  box-shadow: 0 0 0.5rem 0.3rem rgba(var(--lp-text-rgb), 0.1);
  color: var(--lp-text);
  font-size: 1.2rem;
  line-height: 1.5;
  padding: 0.4rem 0.8rem;
  z-index: 99;
}

.lp-calendar-event-lte-icon svg {
  margin-left: 0 !important;
  margin-right: 0.4rem;
  font-size: 1.3rem !important;
  vertical-align: text-top !important;
}

.lp-calendar-availability {
  border-style: solid;
  border-width: 0.1rem;
  border-radius: 0.4rem;
  color: var(--lp-text);
  font-size: 1.4rem;
  margin-bottom: 1.6rem;
  padding: 0.8rem;

  &.free {
    background: rgba(var(--lp-success-rgb), 0.25);
    border-color: var(--lp-success);
  }

  &.busy {
    background: rgba(var(--lp-warning-rgb), 0.25);
    border-color: var(--lp-warning);
  }
}

.lp-calendar-suggestions-title {
  color: var(--lp-secondary);
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}

.lp-calendar-suggestions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.8rem;
  margin-bottom: 1.6rem;

  > div {
    border: 0.1rem solid var(--lp-secondary-400);
    border-radius: 0.4rem;
    cursor: pointer;
    display: flex;
    flex: auto;
    flex-direction: column;
    font-size: 1.2rem;
    gap: 0.4rem;
    padding: 0.8rem;
    transition: 0.4s ease-in-out;

    &:hover {
      box-shadow: 0 0 0.5rem 0.3rem rgba(var(--lp-text-rgb), 0.1);
    }
  }
}
