/* Free/Busy Calendar */
.lp-calendar-date {
  &.free-busy {
    flex-wrap: wrap;

    .form-label {
      color: var(--lp-secondary);
    }
  }
}
